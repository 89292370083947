var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flip-card",
      style: "height:" + _vm.height + ";width:" + _vm.width + ";"
    },
    [
      _c(
        "div",
        {
          staticClass: "flip-card-inner",
          style: _vm.flip ? "transform: rotateY(180deg);" : ""
        },
        [
          _c(
            "v-card",
            { staticClass: "flip-card-front", class: _vm.rounded },
            [
              !_vm.hideInfo
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        absolute: "",
                        right: "",
                        top: "",
                        color: "#cecece"
                      },
                      on: {
                        click: function($event) {
                          return _vm.flipFront()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-information")])],
                    1
                  )
                : _vm._e(),
              _c(
                "v-fade-transition",
                [!_vm.flip ? _vm._t("front") : _vm._e()],
                2
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "flip-card-back", class: _vm.rounded },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    absolute: "",
                    right: "",
                    top: "",
                    color: "#cecece"
                  },
                  on: {
                    click: function($event) {
                      return _vm.flipBack()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-fade-transition", [_vm.flip ? _vm._t("back") : _vm._e()], 2)
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-container>
    <pain-coach
      v-if="!hidePainCoach"
      :style="
        $vuetify.breakpoint.name === 'xl' ? 'margin: 0px 125px 0px 125px' : ''
      "
      :class="$vuetify.breakpoint.mdAndUp ? 'px-4' : 'px-0 mx-0'"
      :userId="userId"
      @painArea="
        painArea = $event;
        painAreaLoaded = true;
      "
    />
    <WellnessOnboardingTour
      v-if="onboardUser"
      :painArea="painArea"
      :currentStep="tourStep"
      @step="tourStep = $event"
    />

    <v-dialog
      v-if="notificationDialog && !showWellnessTour"
      v-model="notificationDialog"
      :width="notificationDialogWidth"
    >
      <notification-dialog v-model="notificationDialog" />
    </v-dialog>

    <v-dialog v-if="webcamQuickAssess" v-model="webcamQuickAssess">
      <quick-assess type="webcamAnalysis" v-model="webcamQuickAssess" />
    </v-dialog>
    <v-dialog
      v-if="simsQuickAssess"
      v-model="simsQuickAssess"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <quick-assess type="deskSetup" v-model="simsQuickAssess" />
    </v-dialog>

    <v-dialog
      v-model="feedbackDialog"
      v-if="feedbackDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'"
      persistent
    >
      <feedback-dialog v-model="feedbackDialog" />
    </v-dialog>

    <v-dialog
      v-model="painManagementFeaturePopup"
      v-if="showPainCoachStatsFeaturePopup"
      max-width="600px"
    >
      <pain-coach-intro-dialog v-model="painManagementFeaturePopup" />
    </v-dialog>

    <feedback-button
      v-if="!assessmentOpen"
      v-model="feedbackDialog"
      :style="$vuetify.breakpoint.smAndDown ? '' : 'top:50%;right: 0%'"
    />

    <v-row
      justify="start"
      align="center"
      :style="
        $vuetify.breakpoint.name === 'xl' ? 'margin: 0px 125px 0px 125px' : ''
      "
      :class="$vuetify.breakpoint.mdAndUp ? 'px-4' : 'px-0 mx-0'"
    >
      <v-col cols="12">
        <p class="text-h4 ml-3 mb-0">
          {{ $t("wellness.v1.latestAssessment.title") }}
        </p>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3" id="v-step-2">
        <LastestAssessmentSummary
          :assessment="latestCompleteDeskAssessment"
          :isDeskAssessment="true"
          :assessmentsLoading="assessmentsLoading"
          @QuickAssess="openQuickAssess($event)"
      /></v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
        v-if="!hideBurnoutCompletely"
      >
        <LastestAssessmentSummary
          :assessment="latestCompleteBurnoutAssessment"
          :isDeskAssessment="false"
          :assessmentsLoading="assessmentsLoading"
      /></v-col>
      <v-col cols="12" :lg="hideBurnoutCompletely ? '9' : '6'">
        <WellnessAssessmentTable
          v-model="assessmentOpen"
          :assessments="assessments"
      /></v-col>
      <v-col>
        <WellnessRecommendations
          :burnoutAssessment="burnoutResult"
          :deskAssessment="deskAssessmentResult"
          :assessmentsLoading="recommendationsLoading"
      /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LastestAssessmentSummary from "./v1/LatestAssessmentSummary.vue";
import WellnessAssessmentTable from "./v1/WellnessAssessmentTable.vue";
import WellnessRecommendations from "./v1/WellnessRecommendations.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import {
  GetLatestBurnoutAssessmentResult,
  GetLatestDeskAssessmentResult,
  getAssessmentTableHistoryData
} from "@/services/modules/api-queries";
import {
  getCurrentUserMostRecentAssessment,
  getSimpleUserDetails
} from "@/customApi";
import VitrueNewFeature from "@/components/common/VitrueNewFeature.vue";
import FeedbackButton from "@/components/common/Feedback/FeedbackButton.vue";
import FeedbackDialog from "@/components/common/Feedback/FeedbackDialog.vue";
import NotificationDialog from "@/components/common/NotificationDialog.vue";
import NewFeatureService from "@/services/new-feature-service.js";
import { processAdminChecklist } from "@/services/onboarding/adminOnboarding";
import FeatureService from "@/services/new-feature-service";
import WellnessOnboardingTour from "@/components/account/onboarding/WellnessOnboardingTour";
import PainCoach from "@/components/dashboard/wellness/PainCoach.vue";
import PainCoachIntroDialog from "@/components/dashboard/wellness/PainCoachIntroDialog.vue";
import TeamService from "@/services/teamService.js";

import _ from "lodash";

const QuickAssess = () => import("@/components/dashboard/QuickAssess.vue");

export default {
  name: "wellness",
  components: {
    WellnessOnboardingTour,
    LastestAssessmentSummary,
    WellnessAssessmentTable,
    WellnessRecommendations,
    QuickAssess,
    VitrueNewFeature,
    FeedbackButton,
    FeedbackDialog,
    NotificationDialog,
    VitrueTooltip,
    PainCoach,
    PainCoachIntroDialog
  },
  data() {
    return {
      assessments: null,
      deskAssessmentResult: null,
      burnoutResult: null,
      assessmentsLoading: false,
      latestBurnoutCallComplete: false,
      latestDeskAssessmentCallComplete: false,
      webcamQuickAssess: false,
      simsQuickAssess: false,
      feedbackDialog: false,
      notificationDialog: false,
      painManagementFeaturePopup: true,
      assessmentOpen: false,
      painCoachExercises: [],
      painCoachStatsFeatureIsOn: false,
      painAreaLoaded: false,
      painArea: null,
      tourStep: 0
    };
  },
  async mounted() {
    if (!this.featureIntros) {
      await FeatureService.getActiveFeatureIntros();
    }

    this.painCoachStatsFeatureIsOn = await NewFeatureService.showFeature(
      "viewPainCoachStats"
    );
  },
  async created() {
    window.scrollTo(0, 0);
    await this.setUserData();
    await this.getAssessments();
    await this.getLatestDeskAssessment();
    await this.getLatestBurnoutAssessment();
    await processAdminChecklist("viewPainCoach");
    this.notificationDialog =
      this.showTextNotifications &&
      !localStorage.getItem("wellnessNotificationDialogSeen");
  },
  computed: {
    ...mapGetters([
      "userId",
      "userEmail",
      "isSimpleUser",
      "showIncompleteAssessmentDialog",
      "showTextNotifications",
      "onboardingChecklist",
      "modalOpen",
      "showOnboardingChecklist",
      "featureIntros",
      "hideBurnoutCompletely",
      "vitrueDeveloper",
      "hidePainCoach",
      "teamMemberId"
    ]),
    showWellnessTour() {
      if (!this.featureIntros) {
        return false;
      }
      return !!_.find(
        this.featureIntros,
        intro => intro.series === "WellnessTour" && !intro.completed
      );
    },
    recommendationsLoading() {
      return (
        this.assessments === null ||
        (this.assessments.length > 0 &&
          !this.latestBurnoutCallComplete &&
          !this.latestDeskAssessmentCallComplete)
      );
    },
    latestCompleteDeskAssessment() {
      if (!this.assessments) {
        return null;
      }
      return this.assessments.find(x => x.assessmentType === "Desk assessment");
    },
    latestCompleteBurnoutAssessment() {
      if (!this.assessments) {
        return null;
      }
      return this.assessments.find(
        x => x.assessmentType === "Burnout assessment"
      );
    },
    painAreas() {
      return this.deskAssessmentResult?.pain.areas;
    },
    notificationDialogWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "90%" : "650px";
    },
    showPainCoachStatsFeaturePopup() {
      return (
        this.painCoachStatsFeatureIsOn &&
        this.painManagementFeaturePopup &&
        !this.modalOpen
      );
    },
    onboardUser() {
      return (
        this.showWellnessTour &&
        this.deskAssessmentResult &&
        this.latestDeskAssessmentCallComplete &&
        this.painAreaLoaded &&
        !this.modalOpen
      );
    }
  },
  methods: {
    ...mapActions([
      "setSimpleUserDetails",
      "getUserTeamAndSubscriptionBaseInformation"
    ]),
    ...mapMutations(["setuserId", "setTeamMemberId"]),
    async setUserData() {
      let userDetails = await getSimpleUserDetails();

      if (this.isSimpleUser) {
        await this.setSimpleUserDetails(userDetails);
      } else {
        let teamMberInfo = await TeamService.getTeamMemberInfo();
        this.setTeamMemberId(teamMberInfo.teamMemberId);
        await this.getUserTeamAndSubscriptionBaseInformation({
          memberId: this.teamMemberId,
          forceToUpdateExistedInformation: true,
          locale: this._i18n.locale
        });
        this.setuserId(userDetails.userId);
      }

      if (userDetails.incompleteAssessment) {
        this.incompleteAssessment = userDetails.incompleteAssessment;
      }
    },
    async getAssessments() {
      try {
        this.assessmentsLoading = true;
        let assessments = await getAssessmentTableHistoryData(true);
        this.assessments = this.getSortedCompleteAssessments(assessments);
      } catch (error) {
        this.$logger.captureException(error);
      } finally {
        this.assessmentsLoading = false;
      }
    },
    async getLatestDeskAssessment() {
      if (!this.latestCompleteDeskAssessment) {
        this.latestDeskAssessmentCallComplete = true;
        return;
      }
      try {
        this.latestDeskAssessmentCallComplete = false;
        this.deskAssessmentResult = await GetLatestDeskAssessmentResult();
      } catch (error) {
        this.$logger.captureException(error);
      } finally {
        this.latestDeskAssessmentCallComplete = true;
      }
    },
    async getLatestBurnoutAssessment() {
      if (!this.latestCompleteBurnoutAssessment) {
        this.latestBurnoutCallComplete = true;
        return;
      }
      try {
        this.latestBurnoutCallComplete = false;
        this.burnoutResult = await GetLatestBurnoutAssessmentResult();
      } catch (error) {
        this.$logger.captureException(error);
      } finally {
        this.latestBurnoutCallComplete = true;
      }
    },
    getSortedCompleteAssessments(items) {
      items = items.filter(x => !!x.completedAt);
      items.sort((a, b) => {
        return new Date(b.completedAt) - new Date(a.completedAt);
      });
      return items;
    },
    openQuickAssess(type) {
      if (type === "deskSetup") {
        this.simsQuickAssess = true;
      } else if (type === "webcamAnalysis") {
        this.webcamQuickAssess = true;
      }
    }
  },
  watch: {
    feedbackDialog: function (newVal) {
      if (newVal === true) {
        this.$gtag.event(
          "Open regular feedback dialog from wellness dashboard",
          {
            event_category: "Feedback"
          }
        );
      }
    }
  }
};
</script>

<style scoped>
.v-step {
  background-color: white;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>

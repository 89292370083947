<template>
  <v-card id="outcome-measure-questions">
    <v-img
      :src="require('@/assets/images/wellness/bannerBackgroundWithAward.svg')"
      height="150px"
    />
    <v-card-title
      class="justify-center text-center text-h4 primary--text pt-4 text-wrap"
    >
      {{ $t("wellness.v2.feedbackDialog.title") }}
    </v-card-title>
    <v-row justify="center" no-gutters>
      <v-col cols="10">
        <v-row
          justify="center"
          no-gutters
          class="text-body-2 pb-4 text-center text-wrap"
        >
          {{ $t(randomSubHeading) }}
        </v-row>
        <v-row justify="center" no-gutters>
          <div class="text-center text-h6">
            {{ $t("wellness.v2.feedbackDialog.taskQuestion") }}
          </div>
        </v-row>
        <v-row justify="center" no-gutters class="pb-5">
          <FeedbackEmojis
            v-model="reaction"
            :options="painCoachDifficultyOptions"
          />
        </v-row>

        <div id="painareaQuestion" v-if="thisTaskWasForPainArea">
          <div v-for="index in painAreaGroup.length" :key="index">
            <v-row justify="center" no-gutters>
              <div :id="'painQuestionText' + index" class="text-h6 mt-4">
                {{
                  $t("wellness.v2.feedbackDialog.painQuestion", {
                    "0": painAreaTitle(index - 1)
                  })
                }}
              </div>
            </v-row>
            <v-row justify="center" no-gutters>
              <PainLevelQuestion
                v-model="painLevel[index - 1]"
                valueCanBeZero
              />
            </v-row>
          </div>
        </div>
        <v-row justify="center" no-gutters class="pb-6 mb-6">
          <v-btn
            id="doneButton"
            color="primary"
            min-width="160px"
            rounded
            @click="saveAndClose"
            :loading="savingData"
            >{{ $t("buttons.done") }}</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PainLevelQuestion from "@/components/form/PainLevelQuestion";
import FeedbackEmojis from "@/components/common/Feedback/FeedbackEmojis.vue";
import VASPainAreas from "@/components/painareas/PainAreasMale.json";
import DatapointGeneration from "@/services/assessment-results-datapoint-generation";
import VideoDifficultyQuestion from "@/assets/json/DeskAssessment/PainCoachExerciseFeedbackQuestions.json";
import { storePainCoachDailyFeedback } from "@/customApi";
import { mapGetters } from "vuex";
import { EventBus } from "@/services/events/event-bus";

export default {
  name: "WellnessVideoFeedback",
  components: {
    PainLevelQuestion,
    FeedbackEmojis
  },
  props: {
    painAreas: Object,
    userProgrammeName: String
  },
  data() {
    return {
      reaction: null,
      painLevel: [],
      assessmentId: "",
      savingData: false,
      painAreaGroup: [],
      subheadingTexts: [
        "wellness.v2.feedbackDialog.subTitle.1",
        "wellness.v2.feedbackDialog.subTitle.2",
        "wellness.v2.feedbackDialog.subTitle.3",
        "wellness.v2.feedbackDialog.subTitle.4",
        "wellness.v2.feedbackDialog.subTitle.5"
      ],
      painCoachDifficultyOptions: VideoDifficultyQuestion.options
    };
  },
  mounted() {
    this.getPainAreas();
  },
  computed: {
    ...mapGetters(["userEmail"]),
    thisTaskWasForPainArea() {
      return this.userProgrammeName != "generic";
    },
    randomSubHeading() {
      return this.subheadingTexts[
        Math.floor(Math.random() * this.subheadingTexts.length)
      ];
    }
  },
  methods: {
    getPainAreas() {
      if (!this.painAreas) {
        return;
      }

      let reportedPainAreasThatFitThisProgram = [];
      const programName = this.userProgrammeName;

      Object.keys(this.painAreas).forEach(i => {
        if (i.includes(programName)) {
          reportedPainAreasThatFitThisProgram.push(i);
        }
      });

      const numberOfPainAreasThatFitThisProgram =
        reportedPainAreasThatFitThisProgram.length;

      // this will happen for generic exercises (where we ask them to rate the video only)
      if (numberOfPainAreasThatFitThisProgram === 0) {
        return;
      }
      this.painAreaGroup = reportedPainAreasThatFitThisProgram;
      this.painLevel = Array(this.painAreas.length).fill(null);
    },
    painAreaTitle(index) {
      const currentPainArea = VASPainAreas[this.painAreaGroup[index]];
      const painAreaName = this.$t(currentPainArea.name);
      return painAreaName.toLowerCase();
    },
    generateAssessmentDataPointDto() {
      var assessmentDataPointValues =
        DatapointGeneration.getGenericDataPointDTO(
          this.userProgrammeName,
          "videodifficultylevel",
          this.reaction.value
        );

      if (this.thisTaskWasForPainArea) {
        var painDataPoints = DatapointGeneration.getPainDataPointDTO(
          this.painAreaGroup,
          this.painLevel
        );
        assessmentDataPointValues = [
          ...assessmentDataPointValues,
          ...painDataPoints
        ];
      }

      return assessmentDataPointValues;
    },
    async saveAndClose() {
      if (!this.reaction) {
        this.$emit("feedbackSubmitted");
        return;
      }

      this.savingData = true;
      let assessmentDataPointDto = this.generateAssessmentDataPointDto();
      try {
        await storePainCoachDailyFeedback(assessmentDataPointDto);
      } finally {
        this.$emit("feedbackSubmitted");
        EventBus.$emit("updatePainStats");
      }
    }
  }
};
</script>

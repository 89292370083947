var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg py-1 px-4 d-flex flex-column",
      class: !_vm.hasGoal ? "greyScale" : "",
      attrs: {
        id: "statsCard",
        flat: "",
        color: "vitruePaleGrey",
        height: "100%",
        "min-height": _vm.horizontalLayout ? "" : "375",
        disabled: !_vm.hasGoal
      }
    },
    [
      _vm.changeGoal
        ? _c(
            "v-dialog",
            {
              attrs: { "content-class": "dialogOverflow", width: "75%" },
              model: {
                value: _vm.changeGoal,
                callback: function($$v) {
                  _vm.changeGoal = $$v
                },
                expression: "changeGoal"
              }
            },
            [
              _c(
                "vitrue-dialog",
                {
                  attrs: {
                    image: "wellness/changeGoal.png",
                    title: _vm.$t("painAssessment.changeGoalTitle")
                  },
                  model: {
                    value: _vm.changeGoal,
                    callback: function($$v) {
                      _vm.changeGoal = $$v
                    },
                    expression: "changeGoal"
                  }
                },
                [
                  _c("change-goal-dialog", {
                    staticClass: "modalStyle",
                    attrs: { userId: _vm.userId, goal: _vm.goalAsInt },
                    on: {
                      close: function($event) {
                        _vm.changeGoal = false
                      },
                      updatePainArea: function($event) {
                        _vm.changeGoal = false
                        _vm.openPainCheckinDialog()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.painCheckinDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "content-class": "dialogOverflow",
                width:
                  _vm.$vuetify.breakpoint.mdAndDown || _vm.highZoom
                    ? "80%"
                    : "60%"
              },
              model: {
                value: _vm.painCheckinDialog,
                callback: function($$v) {
                  _vm.painCheckinDialog = $$v
                },
                expression: "painCheckinDialog"
              }
            },
            [
              _vm.painCheckinDialog
                ? _c(
                    "vitrue-dialog",
                    {
                      attrs: {
                        image: "wellness/painCheckin.svg",
                        title: _vm.$t("painCheckin.title"),
                        hideCloseButton: _vm.hidePainCheckinCloseButton
                      },
                      model: {
                        value: _vm.painCheckinDialog,
                        callback: function($$v) {
                          _vm.painCheckinDialog = $$v
                        },
                        expression: "painCheckinDialog"
                      }
                    },
                    [
                      _c("pain-checkin", {
                        attrs: { existingPainArea: _vm.painArea.area },
                        on: {
                          close: function($event) {
                            _vm.painCheckinDialog = false
                          },
                          hideCloseButton: function($event) {
                            _vm.hidePainCheckinCloseButton = true
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("v-card-title", { staticClass: "text-h6 mb-0 black--text" }, [
        _vm._v(_vm._s(_vm.$t("wellness.v2.statsCard.title")))
      ]),
      _c(
        "v-row",
        {
          staticClass: "text-center",
          attrs: { "no-gutters": "", justify: "space-around" }
        },
        [
          _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-col",
                {
                  attrs: {
                    cols: _vm.goalsAndStatusColumnSize,
                    "align-self": "center"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "space-around" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-1 text-body-2 vitrueGrey--text",
                          attrs: {
                            cols: _vm.showPainLevel ? 6 : 12,
                            id: "goalTitle"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("wellness.v2.statsCard.goals.title")
                              )
                          )
                        ]
                      ),
                      _vm.showPainLevel
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mb-1 text-body-2 vitrueGrey--text",
                              attrs: { cols: "6", id: "statusTitle" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "wellness.v2.statsCard.status.title." +
                                        (_vm.goal === "ReducePain"
                                          ? "painGoal"
                                          : "otherGoal")
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "space-around" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-2 text-body-1",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            cols: _vm.showPainLevel ? 6 : 12,
                            id: "goalText"
                          },
                          on: {
                            click: function($event) {
                              return _vm.openChangeGoalDialog()
                            }
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.goalText) + " "),
                          _c(
                            "span",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    color: "primary",
                                    "x-small": ""
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.showPainLevel
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mb-2 text-body-1",
                              staticStyle: { cursor: "pointer" },
                              attrs: { cols: "6", id: "painText" },
                              on: {
                                click: function($event) {
                                  return _vm.openPainCheckinDialog()
                                }
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.painText) + " "),
                              _c(
                                "span",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        color: "primary",
                                        "x-small": ""
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-plus-circle-outline")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "space-around" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.showPainLevel ? 6 : 12 } },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center", "no-gutters": "" } },
                            [
                              _c(
                                "div",
                                [
                                  _c("v-img", {
                                    staticClass: "rounded-circle zoom",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      contain: "",
                                      width: "120",
                                      height: "120",
                                      src: require("@/assets/images/" +
                                        _vm.wellnessGoalImage)
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openChangeGoalDialog()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.showPainLevel
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { justify: "center", "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-progress-circular",
                                    {
                                      staticClass: "zoom",
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        rotate: "270",
                                        size: "120",
                                        value: _vm.painArea.level * 10,
                                        width: "8",
                                        color: _vm.painLevelColour
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openPainCheckinDialog()
                                        }
                                      }
                                    },
                                    [
                                      _c("v-row", { attrs: { align: "end" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-h3",
                                            attrs: { id: "painLevel" }
                                          },
                                          [_vm._v(_vm._s(_vm.painArea.level))]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-h6 disabled--text"
                                          },
                                          [_vm._v("/10")]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$vuetify.breakpoint.xs
            ? _c(
                "v-col",
                {
                  staticClass: "mb-4",
                  attrs: {
                    id: "goalSection",
                    cols: "12",
                    "align-self": _vm.$vuetify.breakpoint.xl
                      ? "center"
                      : "start"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "mb-1 text-body-2 vitrueGrey--text",
                      attrs: { id: "goalTitle" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("wellness.v2.statsCard.goals.title")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mb-2 text-body-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: { id: "goalText" },
                      on: {
                        click: function($event) {
                          return _vm.openChangeGoalDialog()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.goalText) + " "),
                      _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                color: "primary",
                                "x-small": ""
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "div",
                        [
                          _c("v-img", {
                            staticClass: "rounded-circle zoom",
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              contain: "",
                              width: "120",
                              height: "120",
                              src: require("@/assets/images/" +
                                _vm.wellnessGoalImage)
                            },
                            on: {
                              click: function($event) {
                                return _vm.openChangeGoalDialog()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showPainLevel && _vm.$vuetify.breakpoint.xs
            ? _c(
                "v-col",
                {
                  staticClass: "mb-4",
                  attrs: {
                    id: "statusSection",
                    cols: "12",
                    "align-self": _vm.$vuetify.breakpoint.xl
                      ? "center"
                      : "start"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "mb-1 text-body-2 vitrueGrey--text",
                      attrs: { id: "statusTitle" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "wellness.v2.statsCard.status.title." +
                                (_vm.goal === "ReducePain"
                                  ? "painGoal"
                                  : "otherGoal")
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mb-2 text-body-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: { id: "painText" },
                      on: {
                        click: function($event) {
                          return _vm.openPainCheckinDialog()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.painText) + " "),
                      _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                color: "primary",
                                "x-small": ""
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-plus-circle-outline")])],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-progress-circular",
                        {
                          staticClass: "zoom",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            rotate: "270",
                            size: "120",
                            value: _vm.painArea.level * 10,
                            width: "8",
                            color: _vm.painLevelColour
                          },
                          on: {
                            click: function($event) {
                              return _vm.openPainCheckinDialog()
                            }
                          }
                        },
                        [
                          _c("v-row", { attrs: { align: "end" } }, [
                            _c(
                              "span",
                              {
                                staticClass: "text-h3",
                                attrs: { id: "painLevel" }
                              },
                              [_vm._v(_vm._s(_vm.painArea.level))]
                            ),
                            _c(
                              "span",
                              { staticClass: "text-h6 disabled--text" },
                              [_vm._v("/10")]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "text-start",
              attrs: {
                id: "statsSection",
                cols: _vm.statsSectionColumnSize,
                "align-self": _vm.horizontalLayout ? "center" : "end"
              }
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    justify: _vm.$vuetify.breakpoint.xs
                      ? "space-between"
                      : "space-around",
                    "no-gutters": "",
                    align: "stretch"
                  }
                },
                _vm._l(_vm.stats, function(stat, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      staticClass: "px-2",
                      attrs: { cols: _vm.individualStatColumnSize }
                    },
                    [
                      _c("v-divider"),
                      _c(
                        "v-row",
                        {
                          staticClass: "text-h6 mb-0",
                          class: _vm.statColor(stat.count, stat.trend),
                          attrs: { "no-gutters": "", justify: "start" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-h5 pr-1",
                              attrs: { id: stat.id + "Count", cols: "auto" }
                            },
                            [_vm._v(_vm._s(stat.count))]
                          ),
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                ref: "trendImageContainer" + stat.id,
                                refInFor: true,
                                staticClass: "fill-height flip-box"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flip-box-inner" },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "fill-height",
                                        attrs: {
                                          align: "center",
                                          "no-gutters": ""
                                        }
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            id: stat.id + "Image",
                                            contain: "",
                                            "max-width": "15px",
                                            src: require("@/assets/images/" +
                                              _vm.trendImage(stat.trend))
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "text-body-2",
                          attrs: { id: stat.id + "Text" }
                        },
                        [_vm._v(" " + _vm._s(stat.description) + " ")]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-row>
    <v-col cols="12">
      <p
        class="text-h4 ml-3"
        :class="isDefaultCycle || showError ? 'mb-0' : ''"
      >
        {{ $t("wellness.v2.title") }}
      </p>
    </v-col>
    <v-col cols="12" v-if="showError" class="py-0">
      <v-alert id="errorMessage" type="error" text>
        <span v-html="$t('genericError')" />
      </v-alert>
    </v-col>
    <v-col cols="12" v-else-if="isDefaultCycle">
      <v-alert
        id="defaultMessage"
        v-if="isDefaultCycle && !showError"
        type="info"
        :text="true"
      >
        <span
          id="startDeskAssessment"
          @click="startDeskAssessment()"
          class="mr-2 text-decoration-underline primary--text"
          style="cursor: pointer"
          >{{ $t("wellness.v2.completeAssessment") }}</span
        >
        {{ $t("wellness.v2.toGetPersonalisedProgramme") }}
      </v-alert>
    </v-col>
    <v-col cols="12" v-else-if="highRiskWarning" class="py-0">
      <v-alert id="highRiskWarning" type="warning" text>
        <span v-html="highRiskWarning" />
      </v-alert>
    </v-col>
    <v-col :cols="painCoachCols" id="v-step-0">
      <pain-coach-cycle
        @cycle="cycle = $event"
        @error="showError = true"
        :painArea="painArea"
      />
    </v-col>
    <v-col
      id="v-step-1"
      :cols="wellnessStatsCols"
      :class="$vuetify.breakpoint.lgAndUp ? 'my-3' : ''"
    >
      <v-skeleton-loader id="statsLoader" v-if="showLoader" type="image" />
      <wellness-stats-card
        v-else
        :userId="userId"
        :userGoal="goal"
        :userStats="stats"
      />
    </v-col>
  </v-row>
</template>

<script>
import WellnessStatsCard from "@/components/dashboard/wellness/WellnessStatsCard.vue";
import PainCoachCycle from "@/components/dashboard/wellness/PainCoachCycle.vue";
import {
  startNewAssessment,
  getPainCoachStats,
  getPainCoachGoal
} from "@/customApi.js";
import { mapGetters } from "vuex";
import { EventBus } from "@/services/events/event-bus";
import { getLatestPainAssessment } from "@/services/modules/api-queries";
import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";

export default {
  name: "PainCoach",
  components: {
    WellnessStatsCard,
    PainCoachCycle
  },
  props: {
    userId: String
  },
  data() {
    return {
      cycle: null,
      showError: false,
      stats: null,
      goal: null,
      showLoader: true,
      loading: true,
      latestPainAssessment: null
    };
  },
  async mounted() {
    this.getStatsAndGoal();
    EventBus.$on("painCoachTaskCompleted", this.getStatsAndGoal);
    EventBus.$on("updatePainStats", this.getStatsAndGoal);
  },
  beforeDestroy() {
    EventBus.$off("painCoachTaskCompleted");
    EventBus.$off("updatePainStats");
  },
  computed: {
    ...mapGetters(["userEmail", "disableAssessments"]),
    painCoachCols() {
      if (this.$vuetify.breakpoint.xl) {
        return 8;
      }
      return 12;
    },
    wellnessStatsCols() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }
      return 12;
    },
    isDefaultCycle() {
      return this.cycle?.isDefaultCycle;
    },
    painArea() {
      let area = {};
      if (!this.goal?.painArea) {
        return area;
      }
      area[this.goal.painArea.area] = {};
      return area;
    },
    highRiskWarning() {
      if (this.loading || !this.goal.painArea) {
        return null;
      }
      let hasHighPain = this.goal.painArea.level > 8;
      let hasRedFlags = DeskAssessmentUtil.hasRedFlags(
        this.goal.painArea.area,
        this.latestPainAssessment.results,
        this.latestPainAssessment.type
      );
      if (hasHighPain) {
        return this.$t("wellness.v2.disclaimer");
      }
      if (hasRedFlags) {
        return this.$t("wellness.v2.redFlagsDisclaimer");
      }
      return null;
    }
  },
  methods: {
    async startDeskAssessment() {
      if (this.disableAssessments) {
        return;
      }
      let invitation = {
        email: { value: this.userEmail },
        assessmentType: "Desk assessment"
      };
      try {
        let assessmentId = await startNewAssessment(invitation);
        let newAssessmentUrl = "/deskassessment/" + assessmentId;
        this.$router.push(newAssessmentUrl);
      } catch (err) {}
    },
    async getStatsAndGoal() {
      try {
        this.loading = true;
        this.showLoader = !this.stats && !this.goal;
        this.stats = await getPainCoachStats();
        this.goal = await getPainCoachGoal();
        this.$emit("painArea", this.goal?.painArea?.area);
        await this.getLatestPainAssessmentResults();
      } catch (err) {
      } finally {
        this.loading = false;
        this.showLoader = false;
      }
    },
    async getLatestPainAssessmentResults() {
      if (!this.goal || !this.goal.painArea) {
        return;
      }

      try {
        let results = await getLatestPainAssessment();
        this.latestPainAssessment = {
          results: results.pain.areas[this.goal.painArea.area],
          type: results.assessmentType
        };
      } catch (e) {}
    }
  }
};
</script>

<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>

import { render, staticRenderFns } from "./WellnessStepper.vue?vue&type=template&id=04b96b8d&scoped=true&"
import script from "./WellnessStepper.vue?vue&type=script&lang=js&"
export * from "./WellnessStepper.vue?vue&type=script&lang=js&"
import style0 from "./WellnessStepper.vue?vue&type=style&index=0&id=04b96b8d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b96b8d",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VCard,VDivider,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04b96b8d')) {
      api.createRecord('04b96b8d', component.options)
    } else {
      api.reload('04b96b8d', component.options)
    }
    module.hot.accept("./WellnessStepper.vue?vue&type=template&id=04b96b8d&scoped=true&", function () {
      api.rerender('04b96b8d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/wellness/WellnessStepper.vue"
export default component.exports
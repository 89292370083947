var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "white--text text-subtitle-1",
      class: _vm.dialogPadding,
      staticStyle: { "overflow-y": "hidden" },
      attrs: { id: "notificationCard", color: "primary" }
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "", color: "white" },
          on: { click: _vm.closeDialog }
        },
        [_c("v-icon", [_vm._v("clear")])],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-img", {
                    attrs: {
                      "max-width": "150px",
                      contain: "",
                      src: require("@/assets/images/undraw_workout.png")
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "text-center" : "",
              attrs: { cols: "12", md: "8" }
            },
            [_vm._v(_vm._s(_vm.$t("notificationDialog.wellness.header")))]
          ),
          _c(
            "v-col",
            {
              staticClass: "black--text",
              class: _vm.$vuetify.breakpoint.smAndDown ? "" : "my-4",
              attrs: { cols: "12", md: "9" }
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("VuePhoneNumberInput", {
                        staticStyle: {},
                        attrs: {
                          id: "numberField",
                          size: "lg",
                          color: "#63a8ff",
                          "valid-color": "#4CAF50",
                          "error-color": "#ff0c3e",
                          "no-example": "",
                          "countries-height": 14,
                          "no-flags": ""
                        },
                        on: {
                          update: function($event) {
                            return _vm.setTelephoneInfo($event)
                          }
                        },
                        model: {
                          value: _vm.number,
                          callback: function($$v) {
                            _vm.number = $$v
                          },
                          expression: "number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "white--text",
                      staticStyle: {
                        "font-size": "9px",
                        "line-height": "12px"
                      },
                      attrs: { cols: "12" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("notificationDialog.wellness.numberSuffixText")
                        )
                      )
                    ]
                  ),
                  _vm.showErrorMessage
                    ? _c(
                        "v-col",
                        {
                          staticClass: "warning--text text-h6",
                          attrs: { cols: "12" }
                        },
                        [_vm._v(_vm._s(_vm.errorMessage))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "" : "my-4",
              attrs: { cols: "12", md: "3", "align-self": "start" }
            },
            [
              _c(
                "v-btn",
                {
                  style: _vm.savebuttonWidth,
                  attrs: {
                    color: "white",
                    bottom: "",
                    loading: _vm.updatingNotification,
                    large: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.addNotification()
                    }
                  }
                },
                [
                  _c(
                    "p",
                    { staticClass: "primary--text my-2" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-4",
                          attrs: { left: "", color: "primary" }
                        },
                        [_vm._v("mdi-email-fast-outline")]
                      ),
                      _vm._v(_vm._s(_vm.$t("buttons.save")) + " ")
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "text-center" : "",
              attrs: { cols: "12" }
            },
            [_vm._v(_vm._s(_vm.$t("notificationDialog.wellness.subHeader")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-card
    id="notificationCard"
    color="primary"
    class="white--text text-subtitle-1"
    :class="dialogPadding"
    style="overflow-y: hidden"
  >
    <v-btn @click="closeDialog" class="close-button" icon color="white">
      <v-icon>clear</v-icon>
    </v-btn>
    <v-row justify="center" align="center">
      <v-col cols="12" md="4" v-if="$vuetify.breakpoint.mdAndUp">
        <v-img
          max-width="150px"
          contain
          src="@/assets/images/undraw_workout.png"
      /></v-col>
      <v-col
        cols="12"
        md="8"
        :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
        >{{ $t("notificationDialog.wellness.header") }}</v-col
      >
      <v-col
        cols="12"
        md="9"
        class="black--text"
        :class="$vuetify.breakpoint.smAndDown ? '' : 'my-4'"
      >
        <v-row no-gutters>
          <v-col cols="12"
            ><VuePhoneNumberInput
              id="numberField"
              v-model="number"
              size="lg"
              color="#63a8ff"
              valid-color="#4CAF50"
              error-color="#ff0c3e"
              style="text"
              no-example
              :countries-height="14"
              no-flags
              @update="setTelephoneInfo($event)"
          /></v-col>
          <v-col
            cols="12"
            class="white--text"
            style="font-size: 9px; line-height: 12px"
            >{{ $t("notificationDialog.wellness.numberSuffixText") }}</v-col
          >
          <v-col
            v-if="showErrorMessage"
            cols="12"
            class="warning--text text-h6"
            >{{ errorMessage }}</v-col
          >
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
        align-self="start"
        :class="$vuetify.breakpoint.smAndDown ? '' : 'my-4'"
        ><v-btn
          color="white"
          bottom
          @click="addNotification()"
          :loading="updatingNotification"
          :style="savebuttonWidth"
          large
        >
          <p class="primary--text my-2">
            <v-icon left color="primary" class="mr-4"
              >mdi-email-fast-outline</v-icon
            >{{ $t("buttons.save") }}
          </p>
        </v-btn></v-col
      >
      <v-col
        cols="12"
        :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
        >{{ $t("notificationDialog.wellness.subHeader") }}</v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { setPhoneNumberAndNotificationForEndUser } from "@/customApi";

export default {
  name: "NotificationCard",
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      countryCode: "",
      number: "",
      formattedNumber: "",
      validNumber: false,
      updatingNotification: false,
      showErrorMessage: false,
      errorMessage: ""
    };
  },
  computed: {
    savebuttonWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "width: 100%" : "";
    },
    dialogPadding() {
      return this.$vuetify.breakpoint.smAndDown ? "pa-8" : "px-16 py-4";
    }
  },
  methods: {
    async addNotification() {
      try {
        this.updatingNotification = true;
        this.showErrorMessage = false;

        if ((!this.validNumber && this.number) || !this.number) {
          this.errorMessage = this.$t(
            "notificationDialog.wellness.error.invalidNumber"
          );
          this.showErrorMessage = true;
          return;
        }

        let userDto = {
          phoneNumber: this.formattedNumber,
          countryCode: this.countryCode,
          notification: { notificationName: "wellnessTaskReminder", text: true }
        };
        await setPhoneNumberAndNotificationForEndUser(userDto);
        this.closeDialog();
      } catch (err) {
        this.errorMessage = this.$t(
          "notificationDialog.wellness.error.generic"
        );
        this.showErrorMessage = true;
      } finally {
        this.updatingNotification = false;
      }
    },
    setTelephoneInfo(newVal) {
      this.countryCode = newVal.countryCode;
      this.formattedNumber = newVal.formattedNumber;
      this.validNumber = newVal.isValid;
    },
    closeDialog() {
      localStorage.setItem("wellnessNotificationDialogSeen", true);
      this.$emit("input", false);
    }
  }
};
</script>

<template>
  <v-card
    color="black"
    :rounded="$vuetify.breakpoint.xs ? '0' : ''"
    :height="cardHeight"
  >
    <v-btn
      id="close-button"
      icon
      :x-large="$vuetify.breakpoint.mdAndUp"
      color="white"
      @click="userClickedToFinishVideo"
      class="close-button"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="row-header">
      <p id="title" class="white--text text-h5 ml-3 py-2 mb-0">
        {{ title }}
      </p>
    </div>

    <v-row no-gutters>
      <v-col>
        <iframe
          width="100%"
          :height="cardHeight - 2 * topBarHeight"
          :src="link"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </v-col>
    </v-row>
    <v-row
      id="footer"
      no-gutters
      align="center"
      class="row footer white--text pa-2"
    >
    </v-row>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "ArticleViewer",
  props: {
    title: String,
    link: String
  },
  data() {
    return {
      topBarHeight: 50,
      verticalMargin: 120,
      windowHeight: window.innerHeight,
      timer: null,
      duration: 0
    };
  },
  mounted() {
    this.openModal();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.timer = setInterval(() => this.duration++, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.completeTask(this.duration);
    this.closeModal();
  },
  computed: {
    cardHeight() {
      return this.$vuetify.breakpoint.xs
        ? this.windowHeight
        : this.windowHeight - this.verticalMargin;
    }
  },
  methods: {
    ...mapMutations(["openModal", "closeModal"]),
    completeTask(duration) {
      this.$emit("complete", duration);
    },
    userClickedToFinishVideo() {
      this.$emit("close");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    }
  }
};
</script>

<style scoped>
.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 50px;
}

.box .row.content {
  flex: 1 1 auto;
  position: relative;
}

.box .row.footer {
  flex: 0 1 auto;
}

button.full-width {
  width: 100%;
}
</style>

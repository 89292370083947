var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "text-center pa-4 py-10",
      staticStyle: { "overflow-x": "hidden" }
    },
    [
      _c(
        "div",
        {
          ref: "confettiParent",
          staticStyle: {
            position: "absolute",
            top: "0",
            height: "100%",
            width: "100%",
            "pointer-events": "none"
          }
        },
        [
          _c("canvas", {
            ref: "confettiCanvas",
            staticClass: "sticky",
            staticStyle: { "z-index": "5", "pointer-events": "none" },
            attrs: {
              id: "confettiCanvas",
              width: _vm.canvasWidth,
              height: _vm.canvasHeight
            }
          })
        ]
      ),
      _c(
        "v-btn",
        {
          attrs: { absolute: "", top: "", right: "", icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "10" } },
            [
              _c(
                "v-row",
                { staticClass: "mb-5", attrs: { justify: "center" } },
                [
                  _c("BouncingImage", {
                    attrs: {
                      height: _vm.$vuetify.breakpoint.smAndDown ? 100 : 200,
                      width: _vm.$vuetify.breakpoint.smAndDown ? 100 : 200,
                      imageSource: "wellness/flame.png"
                    }
                  })
                ],
                1
              ),
              _c("p", { staticClass: "text-h5" }, [
                _vm._v(_vm._s(_vm.$t("wellness.v2.cycleComplete.title")))
              ]),
              _c("p", { staticClass: "mb-5" }, [
                _c("span", { staticClass: "text-h2 secondary--text" }, [
                  _vm._v(_vm._s(_vm.percentageComplete) + "%")
                ]),
                _c("span", { staticClass: "text-h5 mx-2" }, [
                  _vm._v(_vm._s(_vm.$t("wellness.v2.cycleComplete.of")))
                ]),
                _c("span", { staticClass: "text-h4 secondary--text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("wellness.v2.cycleComplete.yourExercise", {
                        "0": _vm.programmeArea
                      })
                    )
                  )
                ])
              ]),
              _c(
                "p",
                {
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "text-h6"
                    : "text-h5"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("wellness.v2.cycleComplete.explanation")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
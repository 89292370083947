var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showColumns
      ? _c(
          "div",
          { staticStyle: { position: "relative" } },
          [
            _c(
              "v-row",
              {
                attrs: { id: "columnLayout", align: "center", justify: "start" }
              },
              _vm._l(_vm.tasks, function(task, index) {
                return _c(
                  "v-col",
                  {
                    key: task.taskId,
                    attrs: { sm: _vm.tasks.length > 1 ? 6 : 12, md: "6" }
                  },
                  [
                    _c("wellness-task", {
                      attrs: {
                        id: "task" + index,
                        painAreas: _vm.painArea,
                        programmeTask: task,
                        lockTask: task.locked,
                        showDisclaimer: _vm.showDisclaimer
                      },
                      on: {
                        closed: function($event) {
                          task.closed = true
                        },
                        acceptedDisclaimer: function($event) {
                          _vm.disclaimerAccepted = true
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      : _c(
          "div",
          { staticStyle: { position: "relative" } },
          [
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c(
                  "v-slide-group",
                  {
                    attrs: {
                      mandatory: "",
                      "show-arrows": "always",
                      "center-active": "",
                      id: "slideGroup"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prev",
                        fn: function() {
                          return [
                            _vm.activeTask > 0
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: { click: _vm.decrementCard }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          id: "prev",
                                          size: "40",
                                          color: "primary"
                                        }
                                      },
                                      [_vm._v(" mdi-chevron-left ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "next",
                        fn: function() {
                          return [
                            _vm.activeTask < _vm.tasks.length - 1
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: { click: _vm.incrementCard }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          id: "next",
                                          size: "40",
                                          color: "primary"
                                        }
                                      },
                                      [_vm._v(" mdi-chevron-right ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.activeTask,
                      callback: function($$v) {
                        _vm.activeTask = $$v
                      },
                      expression: "activeTask"
                    }
                  },
                  _vm._l(_vm.tasks, function(task) {
                    return _c(
                      "v-slide-item",
                      { key: task.taskId, staticClass: "mx-2 mb-7" },
                      [
                        _c("wellness-task", {
                          attrs: {
                            painAreas: _vm.painArea,
                            programmeTask: task,
                            lockTask: task.locked,
                            maxWidth: "220px",
                            showDisclaimer: _vm.showDisclaimer
                          },
                          on: {
                            closed: function($event) {
                              task.closed = true
                            },
                            acceptedDisclaimer: function($event) {
                              _vm.disclaimerAccepted = true
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
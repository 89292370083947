<template>
  <div class="flip-card" :style="'height:' + height + ';width:' + width + ';'">
    <div
      class="flip-card-inner"
      :style="flip ? 'transform: rotateY(180deg);' : ''"
    >
      <v-card class="flip-card-front" :class="rounded">
        <v-btn
          v-if="!hideInfo"
          icon
          absolute
          right
          top
          @click="flipFront()"
          color="#cecece"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-fade-transition>
          <slot name="front" v-if="!flip" />
        </v-fade-transition>
      </v-card>
      <v-card class="flip-card-back" :class="rounded">
        <v-btn icon absolute right top @click="flipBack()" color="#cecece">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-fade-transition>
          <slot name="back" v-if="flip" />
        </v-fade-transition>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlipCard",
  props: {
    value: Boolean,
    height: String,
    width: String,
    rounded: String,
    hideInfo: Boolean
  },
  data() {
    return { flip: this.value };
  },
  watch: {
    value() {
      this.flip = this.value;
    }
  },
  methods: {
    flipFront() {
      this.flip = true;
      this.$emit("input", this.flip);
    },
    flipBack() {
      this.flip = false;
      this.$emit("input", this.flip);
    }
  }
};
</script>

<style scoped>
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}
</style>

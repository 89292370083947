<template>
  <div>
    <div v-if="showColumns" style="position: relative">
      <v-row id="columnLayout" align="center" justify="start">
        <v-col
          :sm="tasks.length > 1 ? 6 : 12"
          md="6"
          v-for="(task, index) in tasks"
          :key="task.taskId"
        >
          <wellness-task
            :id="`task${index}`"
            :painAreas="painArea"
            :programmeTask="task"
            :lockTask="task.locked"
            :showDisclaimer="showDisclaimer"
            @closed="task.closed = true"
            @acceptedDisclaimer="disclaimerAccepted = true"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else style="position: relative">
      <v-row justify="center">
        <v-slide-group
          v-model="activeTask"
          mandatory
          show-arrows="always"
          center-active
          id="slideGroup"
        >
          <template v-slot:prev>
            <v-btn icon @click="decrementCard" v-if="activeTask > 0">
              <v-icon id="prev" size="40" color="primary">
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:next>
            <v-btn
              icon
              @click="incrementCard"
              v-if="activeTask < tasks.length - 1"
            >
              <v-icon id="next" size="40" color="primary">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </template>
          <v-slide-item
            v-for="task in tasks"
            :key="task.taskId"
            class="mx-2 mb-7"
          >
            <wellness-task
              :painAreas="painArea"
              :programmeTask="task"
              :lockTask="task.locked"
              maxWidth="220px"
              :showDisclaimer="showDisclaimer"
              @closed="task.closed = true"
              @acceptedDisclaimer="disclaimerAccepted = true"
            />
          </v-slide-item>
        </v-slide-group>
      </v-row>
    </div>
  </div>
</template>

<script>
import WellnessTask from "@/components/dashboard/wellness/v2/WellnessTask.vue";

export default {
  name: "PainCoachExercises",
  components: {
    WellnessTask
  },
  props: {
    exercises: Array,
    painArea: Object,
    firstTimeUser: Boolean
  },
  data() {
    return {
      activeTask: 0,
      tasks: this.exercises.sort(this.sortTasks) ?? [],
      disclaimerAccepted: false
    };
  },
  watch: {
    exercises(val) {
      if (val) {
        let tempVal = [...val];
        this.tasks = tempVal.sort(this.sortTasks);
      }
    }
  },
  computed: {
    showColumns() {
      return !this.$vuetify.breakpoint.xs;
    },
    showDisclaimer() {
      return this.firstTimeUser && !this.disclaimerAccepted;
    }
  },
  methods: {
    // If on medium screens, most cards are showing so can skip to the start/end
    // If not, pan through cards one by one when in/decrementing
    incrementCard() {
      if (this.$vuetify.breakpoint.md) {
        this.activeTask = this.tasks.length - 1;
      } else if (this.activeTask < this.tasks.length - 1) {
        this.activeTask++;
      }
    },
    decrementCard() {
      if (this.$vuetify.breakpoint.md) {
        this.activeTask = 0;
      } else if (this.activeTask > 0) {
        this.activeTask--;
      }
    },
    sortTasks(taskA, taskB) {
      if (
        taskA.userProgrammeName !== "generic" &&
        taskB.userProgrammeName === "generic"
      ) {
        return -1;
      }

      if (
        taskB.userProgrammeName !== "generic" &&
        taskA.userProgrammeName === "generic"
      ) {
        return 1;
      }

      return 0;
    }
  }
};
</script>

<style scoped>
.sliderNavigationButton {
  position: absolute;
  top: 165px;
  margin: auto;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: "outcome-measure-questions" } },
    [
      _c("v-img", {
        attrs: {
          src: require("@/assets/images/wellness/bannerBackgroundWithAward.svg"),
          height: "150px"
        }
      }),
      _c(
        "v-card-title",
        {
          staticClass:
            "justify-center text-center text-h4 primary--text pt-4 text-wrap"
        },
        [_vm._v(" " + _vm._s(_vm.$t("wellness.v2.feedbackDialog.title")) + " ")]
      ),
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "text-body-2 pb-4 text-center text-wrap",
                  attrs: { justify: "center", "no-gutters": "" }
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.randomSubHeading)) + " ")]
              ),
              _c("v-row", { attrs: { justify: "center", "no-gutters": "" } }, [
                _c("div", { staticClass: "text-center text-h6" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("wellness.v2.feedbackDialog.taskQuestion")
                      ) +
                      " "
                  )
                ])
              ]),
              _c(
                "v-row",
                {
                  staticClass: "pb-5",
                  attrs: { justify: "center", "no-gutters": "" }
                },
                [
                  _c("FeedbackEmojis", {
                    attrs: { options: _vm.painCoachDifficultyOptions },
                    model: {
                      value: _vm.reaction,
                      callback: function($$v) {
                        _vm.reaction = $$v
                      },
                      expression: "reaction"
                    }
                  })
                ],
                1
              ),
              _vm.thisTaskWasForPainArea
                ? _c(
                    "div",
                    { attrs: { id: "painareaQuestion" } },
                    _vm._l(_vm.painAreaGroup.length, function(index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center", "no-gutters": "" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-h6 mt-4",
                                  attrs: { id: "painQuestionText" + index }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "wellness.v2.feedbackDialog.painQuestion",
                                          { "0": _vm.painAreaTitle(index - 1) }
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-row",
                            { attrs: { justify: "center", "no-gutters": "" } },
                            [
                              _c("PainLevelQuestion", {
                                attrs: { valueCanBeZero: "" },
                                model: {
                                  value: _vm.painLevel[index - 1],
                                  callback: function($$v) {
                                    _vm.$set(_vm.painLevel, index - 1, $$v)
                                  },
                                  expression: "painLevel[index - 1]"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "v-row",
                {
                  staticClass: "pb-6 mb-6",
                  attrs: { justify: "center", "no-gutters": "" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "doneButton",
                        color: "primary",
                        "min-width": "160px",
                        rounded: "",
                        loading: _vm.savingData
                      },
                      on: { click: _vm.saveAndClose }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.done")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./WellnessRecommendations.vue?vue&type=template&id=4ba19390&scoped=true&"
import script from "./WellnessRecommendations.vue?vue&type=script&lang=js&"
export * from "./WellnessRecommendations.vue?vue&type=script&lang=js&"
import style0 from "./WellnessRecommendations.vue?vue&type=style&index=0&id=4ba19390&scoped=true&lang=css&"
import style1 from "./WellnessRecommendations.vue?vue&type=style&index=1&id=4ba19390&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba19390",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VChipGroup,VCol,VIcon,VProgressCircular,VRow,VTab,VTabs,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ba19390')) {
      api.createRecord('4ba19390', component.options)
    } else {
      api.reload('4ba19390', component.options)
    }
    module.hot.accept("./WellnessRecommendations.vue?vue&type=template&id=4ba19390&scoped=true&", function () {
      api.rerender('4ba19390', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/wellness/v1/WellnessRecommendations.vue"
export default component.exports
<template>
  <v-card
    class="text-center rounded-lg elevation-0"
    color="vitruePaleGrey"
    :height="cardHeight"
  >
    <v-row v-if="!assessmentsLoading" justify="center" class="fill-height">
      <v-col cols="12" :style="'height:' + cardHeight / 2 + 'px'">
        <v-img
          v-if="!isDeskAssessment"
          :src="burnoutImage"
          :height="gaugeWidth / 2 + 10"
          contain
          class="mb-2"
        />
        <p
          id="score"
          class="text-h6"
          :class="burnoutScoreColor + '--text'"
          v-if="!isDeskAssessment"
        >
          {{ burnoutScore }}
        </p>
        <DeskAssessmentResultVisual
          v-else
          :gaugeWidth="gaugeWidth"
          :score="!!assessment ? assessment.score : 50"
          :disabled="!assessment"
        />
      </v-col>
      <v-col cols="12" align-self="end">
        <p id="title" class="text-h6 font-weight-bold mb-0">{{ title }}</p>
        <p
          id="lastResult"
          v-if="assessment"
          class="text-body-2 disabled--text mb-0"
        >
          {{
            $t("wellness.v1.latestAssessment.lastResult", {
              "0": daysSinceLastResult
            })
          }}
        </p>
        <p
          v-else-if="isBurnoutAndIsDisabled"
          id="noAccessText"
          class="text-body-2 accent--text mb-0"
        >
          {{ $t("wellness.v1.latestAssessment.noAccess") }}
        </p>
        <p v-else id="noResultsText" class="text-body-2 disabled--text mb-0">
          {{ $t("wellness.v1.latestAssessment.noResults") }}
        </p>
      </v-col>
      <v-col cols="12">
        <v-tooltip
          v-if="isDeskAssessment"
          bottom
          :disabled="!disableAssessments"
          color="transparent"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                id="deskAssessmentStartTest"
                class="pr-0 text-h6 text-none"
                color="primary"
                min-width="200px"
                :disabled="disableAssessments"
                @click="startNewAssessment()"
              >
                <v-icon class="mr-2">laptop_chromebook</v-icon>
                {{ $t("wellness.v1.latestAssessment.testNow") }}
                <v-menu offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="showMenu"
                      color="primary lighten-1"
                      class="ml-2 px-0 elevation-0 rounded-l-0"
                      :disabled="disableAssessments"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon large color="primary">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      id="webcamAnalysis"
                      @click="openQuickAssessment('webcamAnalysis')"
                    >
                      <v-list-item-title>{{
                        $t(
                          "wellness.v1.latestAssessment.deskAssessment.webcamAssessment"
                        )
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openQuickAssessment('deskSetup')">
                      <v-list-item-title>{{
                        $t(
                          "wellness.v1.latestAssessment.deskAssessment.deskPlanner"
                        )
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn>
            </div>
          </template>
          <vitrue-tooltip :message="$t('tooltips.trialOrSubHasExpired')" />
        </v-tooltip>
        <v-tooltip
          bottom
          v-else
          :disabled="!isBurnoutAndIsDisabled"
          color="transparent"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                id="burnoutStartTest"
                color="accent"
                class="text-none text-h6"
                :disabled="isBurnoutAndIsDisabled"
                @click="startNewAssessment()"
                min-width="200px"
              >
                <v-icon class="mr-2">mdi-lightning-bolt</v-icon>
                {{ $t("wellness.v1.latestAssessment.testNow") }}
              </v-btn>
            </div>
          </template>
          <vitrue-tooltip
            :message="
              disableAssessments
                ? $t('tooltips.trialOrSubHasExpired')
                : $t('wellness.v1.latestAssessment.noAccess')
            "
          />
        </v-tooltip>
        <p id="title" class="text-body-2 disabled--text mt-3">
          {{ $t("wellness.v1.latestAssessment.reassess") }}
        </p>
      </v-col>
    </v-row>
    <v-row v-else justify="center" align="center" class="fill-height">
      <v-progress-circular
        id="loadingCircle"
        :size="50"
        :width="5"
        indeterminate
        color="disabled"
      >
      </v-progress-circular>
    </v-row>
  </v-card>
</template>

<script>
import VueSpeedometer from "vue-speedometer";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import { mapGetters } from "vuex";
import { startNewAssessment } from "@/customApi";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import DeskAssessmentResultVisual from "@/components/deskassessment/report/content/DeskAssessmentResultVisual.vue";
import BurnoutValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues";

export default {
  name: "LastestAssessmentSummary",
  components: {
    VueSpeedometer,
    VitrueTooltip,
    DeskAssessmentResultVisual
  },
  props: {
    assessment: Object,
    assessmentsLoading: Boolean,
    isDeskAssessment: Boolean
  },
  data() {
    return {
      cardHeight: 360,
      gaugeWidth: 260,
      defaultGaugeScore: 50,
      deskAssessmentThresholds: {
        min: 0,
        average: 35,
        good: 62,
        veryGood: 78,
        max: 100
      }
    };
  },
  computed: {
    ...mapGetters(["userEmail", "burnout", "disableAssessments"]),
    segmentColors() {
      if (this.assessment) {
        return [
          this.$vuetify.theme.currentTheme.vitrueRed,
          this.$vuetify.theme.currentTheme.vitrueYellow,
          this.$vuetify.theme.currentTheme.vitrueBrightGreen,
          this.$vuetify.theme.currentTheme.vitrueDarkGreen
        ];
      }

      return [this.$vuetify.theme.currentTheme.disabled];
    },
    title() {
      if (this.isDeskAssessment) {
        return this.$t("wellness.v1.latestAssessment.deskAssessment.title");
      }
      return this.$t("wellness.v1.latestAssessment.burnout.title");
    },
    burnoutScore() {
      if (!this.assessment) {
        return "-";
      }
      return this.burnoutSeverity + " - " + this.burnoutPercentage;
    },
    burnoutSeverity() {
      return BurnoutScores.getRiskFromScore(this.assessment.score, "overall");
    },
    burnoutPercentage() {
      return BurnoutScores.getRiskPercentage(this.assessment.score) + "%";
    },
    daysSinceLastResult() {
      let completedDate = Date.parse(this.assessment?.completedAt);
      let timeDifference = Date.now() - completedDate;
      return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    },
    burnoutScoreColor() {
      if (!this.assessment) {
        return "disabled";
      }
      return BurnoutScores.getCategoryColor(this.assessment.score, "overall");
    },
    isBurnoutAndIsDisabled() {
      return (
        !this.isDeskAssessment && (!this.burnout || this.disableAssessments)
      );
    },
    burnoutImage() {
      let score = this.assessment?.score;
      if (score === null || score === undefined) {
        return require("@/assets/images/burnoutassessment/defaultBurnout.png");
      }

      if (score < BurnoutValues.overall.average) {
        return require("@/assets/images/burnoutassessment/lowBurnout.png");
      } else if (score < BurnoutValues.overall.high) {
        return require("@/assets/images/burnoutassessment/averageBurnout.png");
      } else if (score < BurnoutValues.overall.veryhigh) {
        return require("@/assets/images/burnoutassessment/highBurnout.png");
      }
      return require("@/assets/images/burnoutassessment/veryHighBurnout.png");
    }
  },
  methods: {
    openQuickAssessment(type) {
      this.$emit("QuickAssess", type);
    },
    async startNewAssessment() {
      let assessmentType = this.isDeskAssessment
        ? "Desk assessment"
        : "Burnout assessment";
      let invitation = {
        email: { value: this.userEmail },
        assessmentType: assessmentType
      };
      try {
        let assessmentId = await startNewAssessment(invitation);
        let initialURLPath =
          "/" + assessmentType.replace(" ", "").toLowerCase() + "/";
        let newAssessmentUrl = initialURLPath + assessmentId;
        this.$router.push(newAssessmentUrl, () => {});
      } catch (err) {}
    }
  }
};
</script>

<template>
  <v-card class="text-center pa-4 py-10" style="overflow-x: hidden">
    <div
      ref="confettiParent"
      style="
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
      "
    >
      <canvas
        id="confettiCanvas"
        ref="confettiCanvas"
        class="sticky"
        style="z-index: 5; pointer-events: none"
        :width="canvasWidth"
        :height="canvasHeight"
      >
      </canvas>
    </div>
    <v-btn absolute top right icon @click="$emit('close')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row justify="center" align="center">
      <v-col cols="12" lg="10">
        <v-row justify="center" class="mb-5">
          <BouncingImage
            :height="$vuetify.breakpoint.smAndDown ? 100 : 200"
            :width="$vuetify.breakpoint.smAndDown ? 100 : 200"
            imageSource="wellness/flame.png"
          />
        </v-row>
        <p class="text-h5">{{ $t("wellness.v2.cycleComplete.title") }}</p>
        <p class="mb-5">
          <span class="text-h2 secondary--text">{{ percentageComplete }}%</span>
          <span class="text-h5 mx-2">{{
            $t("wellness.v2.cycleComplete.of")
          }}</span>
          <span class="text-h4 secondary--text">{{
            $t("wellness.v2.cycleComplete.yourExercise", { "0": programmeArea })
          }}</span>
        </p>
        <p :class="$vuetify.breakpoint.smAndDown ? 'text-h6' : 'text-h5'">
          {{ $t("wellness.v2.cycleComplete.explanation") }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);
import BouncingImage from "@/components/common/animations/BouncingImage";

export default {
  name: "UserProgrammeComplete",
  props: {
    percentageComplete: Number,
    programmeArea: String
  },
  data() {
    return { canvasWidth: 0, canvasHeight: 0 };
  },
  components: {
    BouncingImage
  },
  mounted() {
    this.startConfetti();
  },
  methods: {
    async startConfetti() {
      this.$nextTick(() => {
        this.setCanvasDimensions();
        this.$confetti.start({
          canvasElement: this.$refs.confettiCanvas,
          particlesPerFrame: 0.75,
          particles: [
            {
              type: "rect"
            }
          ]
        });
      });

      setTimeout(() => {
        this.$confetti.stop();
      }, 3000);
    },
    setCanvasDimensions() {
      let parent = this.$refs["confettiParent"];
      this.canvasWidth = parent.offsetWidth;
      this.canvasHeight = parent.offsetHeight;
    }
  }
};
</script>

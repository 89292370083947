<template>
    <v-card color="vitruePaleGrey" class="rounded-lg elevation-0" height="360">
      <v-dialog
        v-model="showPatientReport"
        fullscreen
        transition="dialog-bottom-transition"
        scrollable
      >
        <PatientHistoryDialog
          v-if="showPatientReport"
          v-model="showPatientReport"
          :assessment="selectedAssessment"
          :results="results"
        />
      </v-dialog>
      <v-row v-if="assessments" class="mx-7">
        <v-col cols="12">
        <div class="text-h6 mb-n2">
          {{ $t("wellness.v1.assessmentHistory.title") }}
        </div>
        </v-col>
        <v-col>
        <v-data-table
          class="vitruePaleGrey"
          :loading="!assessments"
          :headers="headers"
          :items="assessmentsToShow"
          :items-per-page="-1"
          hide-default-footer
          height="250"
        >
          <template slot="no-data">
            <v-card color="transparent" class="elevation-0 text-center">
              <v-row justify="center">
                <v-img
                  contain
                  src="@/assets/images/undraw_no_data.svg"
                  max-width="150"
                ></v-img>
              </v-row>
              <p>{{ $t("wellness.v1.assessmentHistory.noData") }}</p>
            </v-card>
          </template>
          <template v-slot:item.assessmentType="{ item }">
            <v-progress-circular
              v-if="
                !results &&
                !!selectedAssessment &&
                selectedAssessment.id === item.id
              "
              indeterminate
              size="20"
              width="2"
            />

            <span
              v-else
              :id="'assessmentType' + item.id"
              class="text-decoration-underline primary--text"
              @click="showResults(item)"
              style="cursor: pointer"
              >{{ getTranslatedAssessmentType(item.assessmentType) }}
            </span>
          </template>
          <template v-slot:item.completedAt="{ item }">
            <span>{{ new Date(item.completedAt).toLocaleDateString() }} </span>
          </template>
          <template v-slot:item.score="{ item }">
            <span>{{ getAssessmentRating(item) }} </span>
          </template>
          <template v-slot:footer>
            <v-row justify="center" v-if="!atEndOfAssessment" no-gutters>
              <v-btn
                id="loadMoreButton"
                text
                @click="batchStartIndex += assessmentBatchSize"
                color="grey"
                small
              >
                <span class="text-decoration-underline text-none"
                  >{{ $t("wellness.v1.assessmentHistory.loadMore") }}
                </span>
              </v-btn>
            </v-row>
          </template>
        </v-data-table>
        </v-col>
      </v-row>
      <v-row v-else justify="center" align="center" class="fill-height">
        <v-progress-circular
          :size="50"
          :width="5"
          indeterminate
          color="disabled"
        >
        </v-progress-circular>
      </v-row>
    </v-card>
</template>

<script>
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import PatientHistoryDialog from "@/components/common/assessment-history/PatientHistoryDialog";
import { getAssessmentResult } from "@/services/modules/api-queries";
import { mapGetters } from "vuex";

export default {
  name: "WellnessAssessmentTable",
  components: {
    PatientHistoryDialog
  },
  props: {
    assessments: Array
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("wellness.v1.assessmentHistory.headers.date"),
          value: "completedAt"
        },
        {
          text: this.$t("wellness.v1.assessmentHistory.headers.type"),
          value: "assessmentType"
        },
        {
          text: this.$t("wellness.v1.assessmentHistory.headers.result"),
          value: "score"
        }
      ],
      batchStartIndex: 0,
      assessmentBatchSize: 4,
      showPatientReport: false,
      selectedAssessment: null,
      results: null
    };
  },

  watch: {
    showPatientReport(val) {
      if (!val) {
        window.scrollTo(0, 0);
      }
      this.$emit("input", val);
    }
  },
  computed: {
    assessmentsToShow() {
      return this.assessments.slice(
        0,
        this.batchStartIndex + this.assessmentBatchSize
      );
    },
    atEndOfAssessment() {
      return (
        this.batchStartIndex + this.assessmentBatchSize >=
        this.assessments.length
      );
    }
  },
  methods: {
    getAssessmentRating(item) {
      if (item.score == null) {
        return "-";
      }
      if (item.assessmentType === "Desk assessment") {
        return item.score + "%";
      }
      let percentage = BurnoutScores.getRiskPercentage(item.score) + "%";
      let risk = BurnoutScores.getRiskFromScore(item.score, "overall");
      return risk + " - " + percentage;
    },
    getTranslatedAssessmentType(type) {
      if (type === "Desk assessment") {
        return this.$t(
          "dashboardHistoryComponents.assessmentHistoryTable.assessmentTypes.desk"
        );
      } else if (type === "Burnout assessment") {
        return this.$t(
          "dashboardHistoryComponents.assessmentHistoryTable.assessmentTypes.burnout"
        );
      }

      return "-";
    },
    async showResults(item) {
      try {
        this.results = null;
        this.showPatientReport = true;
        this.selectedAssessment = item;
        this.results = await getAssessmentResult(item.id, item.assessmentType);
      } catch (error) {
        this.$logger.captureException(error);
        this.showPatientReport = false;
      }
    }
  }
};
</script>

<style scoped>
/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}
</style>

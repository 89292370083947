<template>
  <div>
    <v-dialog v-model="showUserProgrammeComplete" max-width="1000px">
      <user-programme-complete
        v-if="showUserProgrammeComplete"
        :percentageComplete="percentageComplete"
        :programmeArea="userProgrammeName"
        @close="showUserProgrammeComplete = false"
      />
    </v-dialog>
    <v-dialog
      v-if="showContent"
      v-model="showContent"
      transition="dialog-bottom-transition"
      :fullscreen="$vuetify.breakpoint.xs && !showDisclaimer"
      :max-width="showDisclaimer && !article ? '500px' : ''"
      persistent
    >
      <ArticleViewer
        v-if="article && showContent"
        @close="
          showContent = false;
          feedbackComplete();
        "
        :link="task.link"
        :title="videoTitle"
        :id="task.name"
        @complete="markTaskAsComplete($event)"
      />
      <ExerciseVideo
        v-else-if="showContent"
        :title="videoTitle"
        :id="task.link"
        :link="task.link"
        :disclaimer="$t('wellness.v2.disclaimer')"
        :disclaimerSmallScreen="$t('wellness.v2.disclaimerSmallScreen')"
        :showDisclaimer="showDisclaimer"
        :videoMetaData="task.videoMetaData"
        @close="showContent = false"
        @complete="markTaskAsComplete($event)"
        @accepted="$emit('acceptedDisclaimer')"
        @userClosedVideoPopupClick="userClosedVideoPopup = true"
      />
    </v-dialog>
    <v-dialog
      v-else-if="showUserFeedbackForm"
      v-model="showUserFeedbackForm"
      transition="dialog-bottom-transition"
      :max-width="feedbackWidth"
      persistent
      ><WellnessVideoFeedback
        v-if="showUserFeedbackForm"
        :painAreas="painAreas"
        :userProgrammeName="task.userProgrammeName"
        @feedbackSubmitted="feedbackComplete()"
      />
    </v-dialog>

    <v-skeleton-loader
      v-if="!task"
      id="skeleton"
      class="rounded-xl"
      type="image, card-heading, list-item-three-line"
      :height="cardHeight + 'px'"
      :width="maxWidth ? maxWidth : '100%'"
      elevation="10"
      style="cursor: default"
    />
    <flip-card
      v-else
      v-model="showCompleteMessage"
      :height="cardHeight + 'px'"
      :width="maxWidth ? maxWidth : '100%'"
      rounded="rounded-xl"
      hideInfo
    >
      <template v-slot:front>
        <div style="cursor: default">
          <v-card
            class="rounded-xl parent grow"
            :style="cardHoverStyle"
            :max-width="maxWidth"
            :disabled="lockTask"
            @click="playLink()"
            :ripple="false"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            <div>
              <v-img
                class="rounded-xl"
                id="taskImage"
                :src="imgSource"
                :height="cardHeight"
                :class="greyOutImage ? 'greyScale' : ''"
              >
                <div>
                  <v-img
                    v-if="lockedOrCompleteItem && !hideIcon"
                    id="action"
                    :src="require('@/assets/images/wellness/' + taskIcon)"
                    :height="50"
                    :width="50"
                    contain
                  />
                  <div v-else-if="!hideIcon && !article" id="play-action">
                    <PlayButton
                      id="playButton"
                      colour="#ffffff"
                      :scale="$vuetify.breakpoint.xs ? 1 : 1.5"
                    />
                  </div>
                  <v-img
                    :src="imgSource"
                    class="rounded-xl"
                    :height="cardHeight"
                    :class="greyOutImage ? 'greyScale' : ''"
                  >
                    <p
                      id="title"
                      class="white--text text-center title px-2 py-1"
                      :class="task.time === 'today' ? 'text-h5' : 'text-body-1'"
                    >
                      {{ task.time }}
                    </p>
                    <div class="white description text-left">
                      <div style="position: relative" class="py-2">
                        <div class="box" :class="color" />
                        <p id="description" class="px-6 mb-0">
                          <span class="text-body-1" :class="color + '--text'">{{
                            $t(title)
                          }}</span
                          ><br />
                          <span class="text-body-2">{{ $t(description) }}</span
                          ><br />
                          <v-icon color="black" size="20">mdi-history</v-icon>
                          <span class="text-body-2 ml-2">{{
                            durationText
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </v-img>
                </div>
              </v-img>
            </div>
          </v-card>
        </div>
      </template>
      <template v-slot:back>
        <div
          class="fill-height rounded-xl"
          style="box-shadow: 0px 20px 20px #9e9d99"
        >
          <div
            style="
              position: absolute;
              top: 0;
              height: 100%;
              width: 100%;
              pointer-events: none;
            "
          >
            <canvas
              id="confettiCanvas"
              ref="confettiCanvas"
              class="sticky"
              style="z-index: 5; pointer-events: none"
              :width="$vuetify.breakpoint.mdAndDown ? '240' : '300'"
              :height="cardHeight"
            >
            </canvas>
          </div>
          <v-row align="center" justify="center" class="fill-height mt-0">
            <v-col cols="10">
              <v-row justify="center" id="back">
                <BouncingImage
                  :height="100"
                  :width="100"
                  imageSource="wellness/cycleComplete.png"
                />
              </v-row>
              <v-row justify="center" v-if="!article">
                <p class="text-body-1 mt-2">
                  {{ $t("wellness.v2.exerciseComplete") }}
                </p>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </template>
    </flip-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);
import FlipCard from "@/components/common/FlipCard.vue";
import ExerciseVideo from "@/components/common/exercise-videos/ExerciseVideo.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import BouncingImage from "@/components/common/animations/BouncingImage";
import {
  markWellnessTaskAsComplete,
  getPercentageCompleteOfUserProgrammes
} from "@/customApi.js";
import PlayButton from "@/components/common/animations/PlayButton";
import UserProgrammeComplete from "./UserProgrammeComplete.vue";
import WellnessVideoFeedback from "@/components/common/exercise-videos/WellnessVideoFeedback.vue";
import PainManagementExercises from "@/assets/json/wellness/PainManagementExercises.json";
import PainManagementArticles from "@/assets/json/wellness/PainManagementArticles.json";
import _ from "lodash";
import { EventBus } from "@/services/events/event-bus";
import ArticleViewer from "@/components/common/exercise-videos/ArticleViewer.vue";

export default {
  name: "WellnessTask",
  components: {
    FlipCard,
    ExerciseVideo,
    VitrueTooltip,
    BouncingImage,
    PlayButton,
    UserProgrammeComplete,
    WellnessVideoFeedback,
    ArticleViewer
  },
  props: {
    maxWidth: String,
    programmeTask: Object,
    lockTask: Boolean,
    showDisclaimer: Boolean,
    defaultProgramme: Boolean,
    percentageOfUserProgrammesCompleted: Array,
    painAreas: Object
  },
  data() {
    return {
      showContent: false,
      task: this.programmeTask,
      alreadyComplete: false,
      hover: false,
      showUserProgrammeComplete: false,
      percentageComplete: null,
      userClosedVideoPopup: false,
      showUserFeedbackForm: false,
      videoMetaData: null,
      showCompleteMessage: false
    };
  },
  watch: {
    programmeTask(newVal) {
      this.task = newVal;
      this.alreadyComplete = !!newVal.alreadyComplete;
      this.showCompleteMessage = false;
    }
  },
  computed: {
    durationText() {
      let durationInMins = Math.round(this.task.duration / 60);
      return this.article
        ? this.$t("wellness.v2.articleDuration", {
            0: durationInMins
          })
        : this.$t("wellness.v2.videoDuration", {
            0: durationInMins
          });
    },
    hideIcon() {
      return this.hover && this.task.gif;
    },
    imgSource() {
      if (this.hover && this.task.gif) {
        return this.task.gif;
      }

      try {
        return require("@/assets/images/wellness/" +
          this.task.userProgrammeName.concat("/", this.task.thumbnail));
      } catch (e) {
        return require("@/assets/images/wellness/" + this.task.thumbnail);
      }
    },
    lockedOrCompleteItem() {
      return this.lockTask || this.task.completedAt;
    },
    taskIcon() {
      if (this.lockTask) {
        return "lock.svg";
      }
      if (this.task.completedAt) {
        return "checkCircle.svg";
      }
      return "play.svg";
    },
    videoWidth() {
      return this.showDisclaimer ? "500px" : "";
    },
    feedbackWidth() {
      return this.$vuetify.breakpoint.mdAndDown ? "500px" : "50%";
    },
    greyOutImage() {
      return this.lockTask || (this.task.time === "yesterday" && !this.hover);
    },
    cardHoverStyle() {
      const cursor = this.lockTask ? "cursor:default;" : "";
      const shadow = this.hover
        ? "box-shadow: 0px 25px 25px #c8c9cc;"
        : "box-shadow: 0px 15px 15px #c8c9cc;";
      return cursor + shadow;
    },
    userProgrammeName() {
      if (!this.task) {
        return null;
      }
      if (this.task.userProgrammeName === "generic") {
        return this.$t("wellness.v2.cycleComplete.recommended");
      }
      return this.task.userProgrammeName;
    },
    cardHeight() {
      return this.$vuetify.breakpoint.xs ? 250 : 380;
    },
    videoTitle() {
      return this.task.time.concat(" - ", this.$t(this.title));
    },
    taskInfo() {
      if (!this.task) {
        return null;
      }
      var programme = this.task.userProgrammeName;
      var task = this.task.name;
      if (this.article) {
        return PainManagementArticles[programme][task];
      }
      return PainManagementExercises[programme][task];
    },
    title() {
      return this.taskInfo?.title;
    },
    description() {
      if (!this.task) {
        return "";
      }

      if (typeof this.taskInfo?.description !== "string") {
        return this.$te(this.taskInfo?.description.noPain) &&
          Object.keys(this.painAreas).length === 0
          ? this.taskInfo?.description.noPain
          : this.taskInfo?.description.pain;
      }
      return this.taskInfo?.description;
    },
    color() {
      return this.taskInfo?.color;
    },
    article() {
      return this.task?.type == "Article";
    }
  },
  methods: {
    async playLink() {
      if (!this.lockTask && this.task.link) {
        this.$mixpanel.track(`Click on task`, { task: this.task.name });
        this.showContent = true;
      }
    },
    async markTaskAsComplete(videoLength) {
      try {
        let task = {
          id: this.task.id,
          name: this.task.name,
          userProgrammeId: this.task.userProgrammeId,
          duration: videoLength,
          type: this.task.type
        };
        await markWellnessTaskAsComplete([task]);
        this.$mixpanel.track(`Completed task`, { task: this.task.name });
        let today = new Date();
        this.task.completedAt = today.toDateString();
        this.showUserFeedbackForm = !this.article;
      } catch (err) {}
    },
    setupConfetti() {
      this.$confetti.start({
        canvasElement: this.$refs.confettiCanvas,
        particlesPerFrame: 0.75,
        particles: [
          {
            type: "rect"
          }
        ]
      });

      setTimeout(() => {
        this.$confetti.stop();
      }, 3000);
    },
    async cycleCompleted() {
      try {
        this.percentageComplete = await getPercentageCompleteOfUserProgrammes(
          this.task
        );
        this.showUserProgrammeComplete = true;
      } catch (err) {}
    },
    showCardFace(face) {
      switch (face) {
        case "front":
          if (this.showCompleteMessage) {
            this.showCompleteMessage = false;
          }
          return;
        case "back":
          if (!this.showCompleteMessage) {
            this.showCompleteMessage = true;
          }
          return;
      }
    },
    async feedbackComplete() {
      this.showUserFeedbackForm = false;

      this.showCardFace("back");
      this.$nextTick(() => {
        this.setupConfetti();
      });

      EventBus.$emit("painCoachTaskCompleted");

      if (
        this.showCompleteMessage &&
        this.task.endTask &&
        this.task.completedAt
      ) {
        await this.cycleCompleted();
      }
    }
  }
};
</script>

<style scoped>
#action {
  position: absolute;
  margin: auto;
  top: 35%;
  left: 0;
  right: 0;
  z-index: 10;
}
#play-action {
  position: absolute;
  margin: auto;
  top: 25%;
  left: 0;
  right: 0;
  z-index: 10;
}
.description {
  bottom: 0;
  width: 100%;
  position: absolute;
  line-height: 15px;
}
.title {
  position: absolute;
  margin: auto;
  width: 70%;
  left: 0;
  right: 0;
  line-height: 120%;
}
.parent {
  position: relative;
}
.box {
  width: 10px;
  height: 20px;
  top: 10px;
  position: absolute;
}

.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.05);
  box-shadow: 0px 50px 50px #000000;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1000px" },
          model: {
            value: _vm.showUserProgrammeComplete,
            callback: function($$v) {
              _vm.showUserProgrammeComplete = $$v
            },
            expression: "showUserProgrammeComplete"
          }
        },
        [
          _vm.showUserProgrammeComplete
            ? _c("user-programme-complete", {
                attrs: {
                  percentageComplete: _vm.percentageComplete,
                  programmeArea: _vm.userProgrammeName
                },
                on: {
                  close: function($event) {
                    _vm.showUserProgrammeComplete = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.showContent
        ? _c(
            "v-dialog",
            {
              attrs: {
                transition: "dialog-bottom-transition",
                fullscreen: _vm.$vuetify.breakpoint.xs && !_vm.showDisclaimer,
                "max-width": _vm.showDisclaimer && !_vm.article ? "500px" : "",
                persistent: ""
              },
              model: {
                value: _vm.showContent,
                callback: function($$v) {
                  _vm.showContent = $$v
                },
                expression: "showContent"
              }
            },
            [
              _vm.article && _vm.showContent
                ? _c("ArticleViewer", {
                    attrs: {
                      link: _vm.task.link,
                      title: _vm.videoTitle,
                      id: _vm.task.name
                    },
                    on: {
                      close: function($event) {
                        _vm.showContent = false
                        _vm.feedbackComplete()
                      },
                      complete: function($event) {
                        return _vm.markTaskAsComplete($event)
                      }
                    }
                  })
                : _vm.showContent
                ? _c("ExerciseVideo", {
                    attrs: {
                      title: _vm.videoTitle,
                      id: _vm.task.link,
                      link: _vm.task.link,
                      disclaimer: _vm.$t("wellness.v2.disclaimer"),
                      disclaimerSmallScreen: _vm.$t(
                        "wellness.v2.disclaimerSmallScreen"
                      ),
                      showDisclaimer: _vm.showDisclaimer,
                      videoMetaData: _vm.task.videoMetaData
                    },
                    on: {
                      close: function($event) {
                        _vm.showContent = false
                      },
                      complete: function($event) {
                        return _vm.markTaskAsComplete($event)
                      },
                      accepted: function($event) {
                        return _vm.$emit("acceptedDisclaimer")
                      },
                      userClosedVideoPopupClick: function($event) {
                        _vm.userClosedVideoPopup = true
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm.showUserFeedbackForm
        ? _c(
            "v-dialog",
            {
              attrs: {
                transition: "dialog-bottom-transition",
                "max-width": _vm.feedbackWidth,
                persistent: ""
              },
              model: {
                value: _vm.showUserFeedbackForm,
                callback: function($$v) {
                  _vm.showUserFeedbackForm = $$v
                },
                expression: "showUserFeedbackForm"
              }
            },
            [
              _vm.showUserFeedbackForm
                ? _c("WellnessVideoFeedback", {
                    attrs: {
                      painAreas: _vm.painAreas,
                      userProgrammeName: _vm.task.userProgrammeName
                    },
                    on: {
                      feedbackSubmitted: function($event) {
                        return _vm.feedbackComplete()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !_vm.task
        ? _c("v-skeleton-loader", {
            staticClass: "rounded-xl",
            staticStyle: { cursor: "default" },
            attrs: {
              id: "skeleton",
              type: "image, card-heading, list-item-three-line",
              height: _vm.cardHeight + "px",
              width: _vm.maxWidth ? _vm.maxWidth : "100%",
              elevation: "10"
            }
          })
        : _c("flip-card", {
            attrs: {
              height: _vm.cardHeight + "px",
              width: _vm.maxWidth ? _vm.maxWidth : "100%",
              rounded: "rounded-xl",
              hideInfo: ""
            },
            scopedSlots: _vm._u([
              {
                key: "front",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticStyle: { cursor: "default" } },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "rounded-xl parent grow",
                            style: _vm.cardHoverStyle,
                            attrs: {
                              "max-width": _vm.maxWidth,
                              disabled: _vm.lockTask,
                              ripple: false
                            },
                            on: {
                              click: function($event) {
                                return _vm.playLink()
                              },
                              mouseover: function($event) {
                                _vm.hover = true
                              },
                              mouseleave: function($event) {
                                _vm.hover = false
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-img",
                                  {
                                    staticClass: "rounded-xl",
                                    class: _vm.greyOutImage ? "greyScale" : "",
                                    attrs: {
                                      id: "taskImage",
                                      src: _vm.imgSource,
                                      height: _vm.cardHeight
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm.lockedOrCompleteItem &&
                                        !_vm.hideIcon
                                          ? _c("v-img", {
                                              attrs: {
                                                id: "action",
                                                src: require("@/assets/images/wellness/" +
                                                  _vm.taskIcon),
                                                height: 50,
                                                width: 50,
                                                contain: ""
                                              }
                                            })
                                          : !_vm.hideIcon && !_vm.article
                                          ? _c(
                                              "div",
                                              { attrs: { id: "play-action" } },
                                              [
                                                _c("PlayButton", {
                                                  attrs: {
                                                    id: "playButton",
                                                    colour: "#ffffff",
                                                    scale: _vm.$vuetify
                                                      .breakpoint.xs
                                                      ? 1
                                                      : 1.5
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-img",
                                          {
                                            staticClass: "rounded-xl",
                                            class: _vm.greyOutImage
                                              ? "greyScale"
                                              : "",
                                            attrs: {
                                              src: _vm.imgSource,
                                              height: _vm.cardHeight
                                            }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "white--text text-center title px-2 py-1",
                                                class:
                                                  _vm.task.time === "today"
                                                    ? "text-h5"
                                                    : "text-body-1",
                                                attrs: { id: "title" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.task.time) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "white description text-left"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "py-2",
                                                    staticStyle: {
                                                      position: "relative"
                                                    }
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass: "box",
                                                      class: _vm.color
                                                    }),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "px-6 mb-0",
                                                        attrs: {
                                                          id: "description"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-1",
                                                            class:
                                                              _vm.color +
                                                              "--text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  _vm.title
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  _vm.description
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "black",
                                                              size: "20"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-history"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 ml-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.durationText
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "back",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "fill-height rounded-xl",
                        staticStyle: { "box-shadow": "0px 20px 20px #9e9d99" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0",
                              height: "100%",
                              width: "100%",
                              "pointer-events": "none"
                            }
                          },
                          [
                            _c("canvas", {
                              ref: "confettiCanvas",
                              staticClass: "sticky",
                              staticStyle: {
                                "z-index": "5",
                                "pointer-events": "none"
                              },
                              attrs: {
                                id: "confettiCanvas",
                                width: _vm.$vuetify.breakpoint.mdAndDown
                                  ? "240"
                                  : "300",
                                height: _vm.cardHeight
                              }
                            })
                          ]
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "fill-height mt-0",
                            attrs: { align: "center", justify: "center" }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "10" } },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { justify: "center", id: "back" } },
                                  [
                                    _c("BouncingImage", {
                                      attrs: {
                                        height: 100,
                                        width: 100,
                                        imageSource:
                                          "wellness/cycleComplete.png"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                !_vm.article
                                  ? _c(
                                      "v-row",
                                      { attrs: { justify: "center" } },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-body-1 mt-2" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "wellness.v2.exerciseComplete"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.showCompleteMessage,
              callback: function($$v) {
                _vm.showCompleteMessage = $$v
              },
              expression: "showCompleteMessage"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
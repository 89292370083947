<template>
  <div>
    <v-dialog
      v-model="acceptTourDialog"
      width="90%"
      max-width="700px"
      content-class="dialogOverflow"
      persistent
    >
      <v-card rounded="xl">
        <v-row no-gutters>
          <v-col cols="12" class="pa-0">
            <v-img
              src="@/assets/images/onboardingTour/wellnessOnboarding.jpg"
              contain
              class="topCornersRounded"
            />
          </v-col>
          <v-col cols="12" class="px-3">
            <p
              class="text-center"
              :class="
                $vuetify.breakpoint.xs
                  ? 'text-h6 my-3 font-weight-bold'
                  : 'text-h4 my-5'
              "
            >
              {{ $t("wellness.v2.tour.welcomeTitle") }}
            </p>
            <p
              class="text-body-1 text-center"
              :class="$vuetify.breakpoint.xs ? 'my-3' : ''"
            >
              {{ $t("wellness.v2.tour.welcomeDescription") }}
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters justify="space-around" class="pb-5">
          <v-col cols="12" sm="5" class="my-2 px-2">
            <v-btn @click="completeTour" block color="primary" outlined
              >{{ $t("wellness.v2.tour.reject") }}
            </v-btn></v-col
          >
          <v-col cols="12" sm="5" class="my-2 px-2"
            ><v-btn @click="startTour" block class="gradient white--text">{{
              $t("wellness.v2.tour.accept")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-tour
      name="myTour"
      :steps="steps"
      :options="{ highlight: true }"
      :callbacks="tourCallbacks"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            class="pa-0"
            :highlight="tour.highlight"
          >
            <template v-if="tour.currentStep === 0">
              <div slot="content" style="position: relative">
                <v-row no-gutters justify="center">
                  <v-col cols="auto" class="pa-0 ma-0">
                    <v-img
                      contain
                      src="@/assets/gifs/painCoachNeck.gif"
                      style="z-index: 10"
                    />
                    <p
                      class="black--text ma-1"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'text-body-1'
                          : 'text-h6'
                      "
                    >
                      {{
                        painAreaGroup
                          ? $t("wellness.v2.tour.step1.pain", {
                              0: $t(
                                `wellness.v2.userProgrammeNames.${painAreaGroup}`
                              )
                            })
                          : $t("wellness.v2.tour.step1.noPain")
                      }}
                    </p>
                  </v-col>
                </v-row>
                <v-row justify="end" no-gutters>
                  <v-col
                    cols="4"
                    class="black--text text-caption center-text"
                    align-self="center"
                  >
                    <p class="mb-0">
                      {{ $t("wellness.v2.tour.stepLabel", { 0: "1/3" }) }}
                    </p>
                  </v-col>
                  <v-col cols="4">
                    <v-row justify="end" no-gutters>
                      <v-col cols="auto">
                        <v-btn @click="tour.nextStep" icon
                          ><v-icon large>mdi-arrow-right</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div slot="actions"></div>
            </template>
            <template v-if="tour.currentStep === 1">
              <div slot="content" style="position: relative">
                <v-row no-gutters justify="center">
                  <v-col cols="auto" class="pa-0 ma-0">
                    <v-img
                      contain
                      src="@/assets/gifs/painCoachStats.gif"
                      style="z-index: 10"
                    />
                    <p
                      class="black--text ma-1"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'text-body-1'
                          : 'text-h6'
                      "
                    >
                      {{ $t("wellness.v2.tour.step2") }}
                    </p>
                  </v-col>
                </v-row>
                <v-row justify="space-between" no-gutters>
                  <v-col cols="auto">
                    <v-btn @click="tour.previousStep" icon
                      ><v-icon large>mdi-arrow-left</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="auto"
                    class="black--text text-caption"
                    align-self="center"
                  >
                    <span>{{
                      $t("wellness.v2.tour.stepLabel", { 0: "2/3" })
                    }}</span>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn @click="tour.nextStep" icon
                      ><v-icon large>mdi-arrow-right</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </div>
              <div slot="actions"></div>
            </template>
            <template v-if="tour.currentStep === 2">
              <div slot="content" style="position: relative">
                <v-row no-gutters justify="center">
                  <v-col cols="auto" class="pa-0 ma-0">
                    <v-img
                      contain
                      src="@/assets/gifs/webcamAssessment.gif"
                      style="z-index: 10"
                    />
                    <p
                      class="black--text ma-1"
                      :class="
                        $vuetify.breakpoint.smAndDown
                          ? 'text-body-1'
                          : 'text-h6'
                      "
                    >
                      {{ $t("wellness.v2.tour.step3") }}
                    </p>
                  </v-col>
                </v-row>
                <v-row justify="space-between" no-gutters>
                  <v-col cols="auto">
                    <v-btn @click="tour.previousStep" icon
                      ><v-icon large>mdi-arrow-left</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="auto"
                    class="black--text text-caption"
                    align-self="center"
                  >
                    <span>{{
                      $t("wellness.v2.tour.stepLabel", { 0: "3/3" })
                    }}</span>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn @click="tour.finish" icon
                      ><v-icon color="secondary" large>mdi-check</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </div>
              <div slot="actions"></div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import { setFeatureIntroItemComplete } from "@/customApi";
import { mapGetters, mapMutations } from "vuex";
import { find } from "lodash";
import FeatureService from "@/services/new-feature-service";
import PainAreas from "@/components/painareas/PainAreasMale.json";

export default {
  name: "WellnessOnboardingTour",
  data() {
    return {
      acceptTourDialog: true,
      steps: [
        {
          target: "#v-step-0",
          params: {
            placement: this.$vuetify.breakpoint.xl ? "right" : "bottom"
          },
          offset: -200
        },
        {
          target: "#v-step-1",
          params: {
            placement: this.$vuetify.breakpoint.xl ? "left" : "top"
          },
          offset: -200
        },
        {
          target: "#v-step-2",
          params: {
            placement: this.$vuetify.breakpoint.xl ? "right" : "top"
          },
          offset: -300
        }
      ],
      tourCallbacks: {
        onFinish: this.completeTour,
        onNextStep: this.nextStep,
        onPreviousStep: this.previousStep
      }
    };
  },
  props: {
    painArea: String,
    currentStep: Number
  },
  beforeDestroy() {
    this.endUserTourShowing(false);
  },
  computed: {
    ...mapGetters(["featureIntros"]),
    painAreaGroup() {
      if (!this.painArea) {
        return null;
      }
      return PainAreas[this.painArea].group;
    }
  },
  methods: {
    ...mapMutations(["endUserTourShowing"]),
    startTour() {
      this.acceptTourDialog = false;
      this.endUserTourShowing(true);
      this.$tours["myTour"].start(this.currentStep);
    },
    async completeTour() {
      window.scroll({
        top: 0,
        behavior: "smooth"
      });
      let wellnessFeatureIntroduction = find(
        this.featureIntros,
        intro => intro.series === "WellnessTour"
      );
      let wellnessFeatureIntroductionItem =
        wellnessFeatureIntroduction.items.wellnessTour;
      await setFeatureIntroItemComplete(
        wellnessFeatureIntroduction.id,
        wellnessFeatureIntroductionItem.id
      );
      await FeatureService.getActiveFeatureIntros();
    },
    async nextStep(currentStep) {
      this.$emit("step", currentStep + 1);
    },
    previousStep(currentStep) {
      this.$emit("step", currentStep - 1);
    }
  }
};
</script>

<style scoped>
.v-step {
  background-color: white;
}

.gradient {
  background-image: linear-gradient(#8d67f9, #24c3f9);
}

.topCornersRounded {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            width: "90%",
            "max-width": "700px",
            "content-class": "dialogOverflow",
            persistent: ""
          },
          model: {
            value: _vm.acceptTourDialog,
            callback: function($$v) {
              _vm.acceptTourDialog = $$v
            },
            expression: "acceptTourDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { rounded: "xl" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "12" } },
                    [
                      _c("v-img", {
                        staticClass: "topCornersRounded",
                        attrs: {
                          src: require("@/assets/images/onboardingTour/wellnessOnboarding.jpg"),
                          contain: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "px-3", attrs: { cols: "12" } }, [
                    _c(
                      "p",
                      {
                        staticClass: "text-center",
                        class: _vm.$vuetify.breakpoint.xs
                          ? "text-h6 my-3 font-weight-bold"
                          : "text-h4 my-5"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("wellness.v2.tour.welcomeTitle")) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "text-body-1 text-center",
                        class: _vm.$vuetify.breakpoint.xs ? "my-3" : ""
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("wellness.v2.tour.welcomeDescription")
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "pb-5",
                  attrs: { "no-gutters": "", justify: "space-around" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "my-2 px-2",
                      attrs: { cols: "12", sm: "5" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", color: "primary", outlined: "" },
                          on: { click: _vm.completeTour }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("wellness.v2.tour.reject")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "my-2 px-2",
                      attrs: { cols: "12", sm: "5" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "gradient white--text",
                          attrs: { block: "" },
                          on: { click: _vm.startTour }
                        },
                        [_vm._v(_vm._s(_vm.$t("wellness.v2.tour.accept")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-tour", {
        attrs: {
          name: "myTour",
          steps: _vm.steps,
          options: { highlight: true },
          callbacks: _vm.tourCallbacks
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(tour) {
              return [
                _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    tour.steps[tour.currentStep]
                      ? _c(
                          "v-step",
                          {
                            key: tour.currentStep,
                            staticClass: "pa-0",
                            attrs: {
                              step: tour.steps[tour.currentStep],
                              "previous-step": tour.previousStep,
                              "next-step": tour.nextStep,
                              stop: tour.stop,
                              skip: tour.skip,
                              "is-first": tour.isFirst,
                              "is-last": tour.isLast,
                              labels: tour.labels,
                              highlight: tour.highlight
                            }
                          },
                          [
                            tour.currentStep === 0
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { position: "relative" },
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            justify: "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c("v-img", {
                                                staticStyle: {
                                                  "z-index": "10"
                                                },
                                                attrs: {
                                                  contain: "",
                                                  src: require("@/assets/gifs/painCoachNeck.gif")
                                                }
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "black--text ma-1",
                                                  class: _vm.$vuetify.breakpoint
                                                    .smAndDown
                                                    ? "text-body-1"
                                                    : "text-h6"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.painAreaGroup
                                                          ? _vm.$t(
                                                              "wellness.v2.tour.step1.pain",
                                                              {
                                                                0: _vm.$t(
                                                                  "wellness.v2.userProgrammeNames." +
                                                                    _vm.painAreaGroup
                                                                )
                                                              }
                                                            )
                                                          : _vm.$t(
                                                              "wellness.v2.tour.step1.noPain"
                                                            )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "end",
                                            "no-gutters": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "black--text text-caption center-text",
                                              attrs: {
                                                cols: "4",
                                                "align-self": "center"
                                              }
                                            },
                                            [
                                              _c("p", { staticClass: "mb-0" }, [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "wellness.v2.tour.stepLabel",
                                                        { 0: "1/3" }
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: {
                                                    justify: "end",
                                                    "no-gutters": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: tour.nextStep
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                large: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-arrow-right"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    attrs: { slot: "actions" },
                                    slot: "actions"
                                  })
                                ]
                              : _vm._e(),
                            tour.currentStep === 1
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { position: "relative" },
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            justify: "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c("v-img", {
                                                staticStyle: {
                                                  "z-index": "10"
                                                },
                                                attrs: {
                                                  contain: "",
                                                  src: require("@/assets/gifs/painCoachStats.gif")
                                                }
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "black--text ma-1",
                                                  class: _vm.$vuetify.breakpoint
                                                    .smAndDown
                                                    ? "text-body-1"
                                                    : "text-h6"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "wellness.v2.tour.step2"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "space-between",
                                            "no-gutters": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: tour.previousStep
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { large: "" } },
                                                    [_vm._v("mdi-arrow-left")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "black--text text-caption",
                                              attrs: {
                                                cols: "auto",
                                                "align-self": "center"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "wellness.v2.tour.stepLabel",
                                                      { 0: "2/3" }
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: { click: tour.nextStep }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { large: "" } },
                                                    [_vm._v("mdi-arrow-right")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    attrs: { slot: "actions" },
                                    slot: "actions"
                                  })
                                ]
                              : _vm._e(),
                            tour.currentStep === 2
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { position: "relative" },
                                      attrs: { slot: "content" },
                                      slot: "content"
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            justify: "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c("v-img", {
                                                staticStyle: {
                                                  "z-index": "10"
                                                },
                                                attrs: {
                                                  contain: "",
                                                  src: require("@/assets/gifs/webcamAssessment.gif")
                                                }
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "black--text ma-1",
                                                  class: _vm.$vuetify.breakpoint
                                                    .smAndDown
                                                    ? "text-body-1"
                                                    : "text-h6"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "wellness.v2.tour.step3"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "space-between",
                                            "no-gutters": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: tour.previousStep
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { large: "" } },
                                                    [_vm._v("mdi-arrow-left")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "black--text text-caption",
                                              attrs: {
                                                cols: "auto",
                                                "align-self": "center"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "wellness.v2.tour.stepLabel",
                                                      { 0: "3/3" }
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: { click: tour.finish }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        large: ""
                                                      }
                                                    },
                                                    [_vm._v("mdi-check")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    attrs: { slot: "actions" },
                                    slot: "actions"
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
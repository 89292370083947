<template>
  <v-card rounded="lg" class="pa-3">
    <v-row no-gutters justify="center">
      <v-col class="text-h5 my-3 text-center">{{
        $t("wellness.v2.introductionDialog.title")
      }}</v-col>
      <v-col cols="12" class="mt-3">
        <v-img
          :src="require('@/assets/images/wellness/painCoachStats.png')"
          contain
          height="300px"
        />
      </v-col>
      <v-col cols="12" class="my-5 text-body-1">
        <v-row justify="center" no-gutters>
          <ul>
            <li class="my-2">
              {{ $t("wellness.v2.introductionDialog.goal") }}
            </li>
            <li class="my-2">
              {{ $t("wellness.v2.introductionDialog.exercises") }}
            </li>
            <li class="my-2">
              {{ $t("wellness.v2.introductionDialog.progress") }}
            </li>
          </ul>
        </v-row>
      </v-col>
      <v-col class="mb-4" xs="10" sm="8" md="4">
        <v-btn
          width="100%"
          class="primary"
          rounded
          @click="$emit('input', false)"
        >
          {{ $t("wellness.v2.wellnessTour.button") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NewFeatureService from "@/services/new-feature-service.js";

export default {
  name: "PainCoachIntroDialog",
  data() {
    return { featureId: "viewPainCoachStats" };
  },
  beforeDestroy() {
    NewFeatureService.markFeatureSeen(this.featureId, true);
  }
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        color: "black",
        rounded: _vm.$vuetify.breakpoint.xs ? "0" : "",
        height: _vm.cardHeight
      }
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: {
            id: "close-button",
            icon: "",
            "x-large": _vm.$vuetify.breakpoint.mdAndUp,
            color: "white"
          },
          on: { click: _vm.userClickedToFinishVideo }
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      _c("div", { staticClass: "row-header" }, [
        _c(
          "p",
          {
            staticClass: "white--text text-h5 ml-3 py-2 mb-0",
            attrs: { id: "title" }
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        )
      ]),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c("iframe", {
              attrs: {
                width: "100%",
                height: _vm.cardHeight - 2 * _vm.topBarHeight,
                src: _vm.link,
                frameborder: "0",
                allowfullscreen: ""
              }
            })
          ])
        ],
        1
      ),
      _c("v-row", {
        staticClass: "row footer white--text pa-2",
        attrs: { id: "footer", "no-gutters": "", align: "center" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <p class="text-h4 ml-3 mt-4">
      {{ $t("wellness.v1.recommendations.title") }}
    </p>
    <v-row align="center" class="ml-3">
      <v-col
        cols="12"
        xl="8"
        :class="$vuetify.breakpoint.name === 'xl' ? 'py-0' : 'pb-0'"
      >
        <v-chip-group v-model="selectedRecommendations" column multiple>
          <v-chip
            v-for="group in Object.values(recommendationOptions)"
            :key="group.name"
            :id="group.name"
            :value="group.name"
            :color="group.color"
            :outlined="!isSelected(group.name)"
            :text-color="
              group.name === recommendationOptions.all.name &&
              isSelected(group.name)
                ? 'white'
                : ''
            "
            class="chipSelector text-body-1 mr-4"
          >
            {{ $t("wellness.v1.recommendations.options." + group.name) }}
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-col cols="12" xl="4">
        <v-tabs
          :right="$vuetify.breakpoint.name === 'xl'"
          :left="$vuetify.breakpoint.name !== 'xl'"
          v-model="tab"
          icons-and-text
          color="primary"
          height="50px"
          v-if="
            isSelected(recommendationOptions.desk.name) ||
            isSelected(recommendationOptions.all.name)
          "
        >
          <v-tab href="#budget" class="permanentTabBorder">
            <v-tooltip bottom color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon>attach_money</v-icon>
                </div>
              </template>
              <vitrue-tooltip :message="$t('tooltips.budgetRecommendation')" />
            </v-tooltip>
          </v-tab>

          <v-tab href="#upgrade" class="permanentTabBorder">
            <v-tooltip bottom color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon>attach_money</v-icon>
                  <v-icon>attach_money</v-icon>
                </div>
              </template>
              <vitrue-tooltip :message="$t('tooltips.upgradeRecommendation')" />
            </v-tooltip>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-if="assessmentsLoading && noRecommendations" justify="center">
      <v-progress-circular
        id="loadingSymbol"
        :size="50"
        :width="5"
        indeterminate
        color="disabled"
      />
    </v-row>
    <v-row v-else-if="noRecommendations" justify="center">
      <v-col cols="12">
        <p class="text-h5 mt-6 text-center" id="noRecommendationsText">
          {{ $t("wellness.v1.recommendations.noRecommendations") }}
        </p>
      </v-col>
    </v-row>
    <div v-else>
      <v-row
        v-for="(group, index) in recommendationsToShow"
        :key="'group' + index"
        align="center"
      >
        <v-col
          v-for="card in group"
          :key="card.id"
          cols="12"
          md="6"
          :lg="card.json.type === 'exercise' ? '6' : '4'"
          :xl="card.json.type === 'exercise' ? '4' : '3'"
          class="align-self-stretch px-0"
          :class="card.impact ? 'pt-5' : ''"
        >
          <recommendations-card
            class="ma-4"
            :id="card.id"
            :linkType="card.linkType(combinedResults)"
            :assessmentId="getAssessmentId(card.json.type)"
            :imageName="card.json.imageName"
            :heading="
              card.heading
                ? card.heading(combinedResults)
                : $t(card.json.heading)
            "
            :subheading="$t(card.json.subheading)"
            :explanation="
              card.explanation
                ? card.explanation(combinedResults)
                : $t(card.json.explanation)
            "
            :type="card.json.type"
            :link="linkToShow(card)"
            :emailLink="card.json.emailLink"
            :route="card.json.route"
            :previouslyLiked="likedCards.includes(card.id)"
            :previouslyRequested="previouslyRequested.includes(card.id)"
            :coveredBy="card.json.coveredBy"
            :impact="card.impact"
            :videoDisclaimer="videoDisclaimer"
            :deskAssessment="deskAssessment"
            style="height: 100%"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import RecommendationsCard from "@/components/common/report/cards/RecommendationsCard";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import {
  getBurnoutRecommendations,
  getBurnoutFixedRecommendations,
  getSetupRecommendations,
  displayedExplanationText,
  getDeskFixedRecommendations,
  sortRecommendationsByCost
} from "@/services/recommendations/recommendations-service.js";
import PregnancyReport from "@/services/deskassessment/pregnancy-report.js";
import { getLikedItemsForAssessment, getEquipmentRequests } from "@/customApi";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import { mapGetters } from "vuex";
import { LinkTypes } from "@/services/recommendations/link-types.js";

export default {
  name: "RecommendationsPanel",
  props: {
    burnoutAssessment: Object,
    deskAssessment: Object,
    assessmentsLoading: Boolean
  },
  components: {
    RecommendationsCard,
    VitrueTooltip
  },
  data() {
    return {
      cards: [],
      deskAssessmentLikedCards: [],
      burnoutLikedCards: [],
      previouslyRequested: [],
      activeCard: 0,
      selectedRecommendations: ["all"],
      tab: "budget"
    };
  },
  watch: {
    async burnoutAssessment(newVal) {
      if (newVal && this.burnoutLikedCards.length === 0) {
        this.burnoutLikedCards = await this.getLikedCards(
          this.burnoutAssessment.id
        );
      }
    },
    async deskAssessment(newVal) {
      if (newVal && this.deskAssessmentLikedCards.length === 0) {
        this.deskAssessmentLikedCards = await this.getLikedCards(
          this.deskAssessment.id
        );
        this.previouslyRequested = await getEquipmentRequests(
          this.deskAssessment.id
        );
      }
    },
    selectedRecommendations(newVal, oldVal) {
      let all = this.recommendationOptions.all.name;
      let oldContainsAll = this.isSelected(all, oldVal);
      let newContainsAll = this.isSelected(all, newVal);
      let allTagAndOtherTagSelected = oldContainsAll && newVal.length > 1;
      let allSelected = !oldContainsAll && newContainsAll;
      let noneSelected = newVal.length === 0;

      if (allTagAndOtherTagSelected) {
        let allIndex = newVal.indexOf(all);
        this.selectedRecommendations.splice(allIndex, 1);
      } else if (allSelected || noneSelected) {
        this.selectedRecommendations = [all];
      }
    }
  },
  computed: {
    ...mapGetters(["customRecommendations", "hideBurnoutCompletely"]),
    combinedResults() {
      let desk = this.deskAssessment ?? {};
      let burnout = this.burnoutAssessment ?? {};
      return { ...desk, ...burnout };
    },
    recommendationOptions() {
      var options = {
        all: { name: "all", color: "vitrueGrey" },
        desk: { name: "desk", color: "primary" }
      };

      if (!this.hideBurnoutCompletely) {
        options.burnout = { name: "burnout", color: "accent" };
      }

      return options;
    },
    likedCards() {
      return [...this.deskAssessmentLikedCards, ...this.burnoutLikedCards];
    },
    burnoutRecommendations() {
      if (!this.burnoutAssessment) {
        return [];
      }
      let recommendations = getBurnoutRecommendations(
        BurnoutScores.getScores(this.burnoutAssessment),
        this.burnoutAssessment,
        this.getFeatureFlags()
      );

      let flattened = Object.values(recommendations).flatMap(x => x);
      let defaults = getBurnoutFixedRecommendations(this.getFeatureFlags());
      return [...flattened, ...defaults];
    },
    setupRecommendations() {
      if (!this.deskAssessment) {
        return [];
      }
      let recommendations = getSetupRecommendations(
        this.deskAssessment,
        this.getFeatureFlags()
      );
      let fixedRecommendations = getDeskFixedRecommendations(
        this.getFeatureFlags()
      );

      recommendations = sortRecommendationsByCost(recommendations, this.tab);

      return [...recommendations, ...fixedRecommendations];
    },
    recommendationsToShow() {
      if (this.isSelected(this.recommendationOptions.all.name)) {
        return [this.setupRecommendations, this.burnoutRecommendations];
      }

      let recommendations = [];
      if (this.isSelected(this.recommendationOptions.desk.name)) {
        recommendations.push(this.setupRecommendations);
      }
      if (this.isSelected(this.recommendationOptions.burnout?.name)) {
        recommendations.push(this.burnoutRecommendations);
      }

      return recommendations;
    },
    noRecommendations() {
      return this.recommendationsToShow.flatMap(x => x).length === 0;
    },
    videoDisclaimer() {
      if (!this.deskAssessment) {
        return "";
      }
      if (this.deskAssessment.health.pregnancy === 1) {
        return this.$t("recommendationsCardComponent.disclaimer.pregnancy");
      }
      return this.$t("recommendationsCardComponent.disclaimer.general");
    }
  },
  methods: {
    displayedExplanationText,
    isSelected(option, selectedArray) {
      let array = selectedArray ?? this.selectedRecommendations;
      return array.includes(option);
    },
    getAssessmentId(type) {
      return type === "burnout"
        ? this.burnoutAssessment?.id
        : this.deskAssessment?.id;
    },
    getFeatureFlags() {
      return {
        ...this.customRecommendations,
        pregnancyExerciseVideos: this.deskAssessment?.health?.pregnancy === 1
      };
    },
    async getLikedCards(assessmentId) {
      try {
        return await getLikedItemsForAssessment(assessmentId);
      } catch (err) {}
    },
    linkToShow(card) {
      if (card.linkType(this.combinedResults) === LinkTypes.VIDEO) {
        return PregnancyReport.getExerciseRecommendationUrl(
          card,
          this.deskAssessment
        );
      }
      return card.json.link;
    }
  }
};
</script>

<style scoped>
.chipSelector {
  min-width: 150px;
  justify-content: center;
}
</style>

<style scoped>
.permanentTabBorder {
  border-bottom: 2px solid var(--v-disabled-base);
}
</style>

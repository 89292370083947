var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg elevation-0",
      attrs: { color: "vitruePaleGrey", height: "360" }
    },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: "",
            transition: "dialog-bottom-transition",
            scrollable: ""
          },
          model: {
            value: _vm.showPatientReport,
            callback: function($$v) {
              _vm.showPatientReport = $$v
            },
            expression: "showPatientReport"
          }
        },
        [
          _vm.showPatientReport
            ? _c("PatientHistoryDialog", {
                attrs: {
                  assessment: _vm.selectedAssessment,
                  results: _vm.results
                },
                model: {
                  value: _vm.showPatientReport,
                  callback: function($$v) {
                    _vm.showPatientReport = $$v
                  },
                  expression: "showPatientReport"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.assessments
        ? _c(
            "v-row",
            { staticClass: "mx-7" },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "text-h6 mb-n2" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("wellness.v1.assessmentHistory.title")) +
                      " "
                  )
                ])
              ]),
              _c(
                "v-col",
                [
                  _c(
                    "v-data-table",
                    {
                      staticClass: "vitruePaleGrey",
                      attrs: {
                        loading: !_vm.assessments,
                        headers: _vm.headers,
                        items: _vm.assessmentsToShow,
                        "items-per-page": -1,
                        "hide-default-footer": "",
                        height: "250"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.assessmentType",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                !_vm.results &&
                                !!_vm.selectedAssessment &&
                                _vm.selectedAssessment.id === item.id
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        size: "20",
                                        width: "2"
                                      }
                                    })
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-decoration-underline primary--text",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          id: "assessmentType" + item.id
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showResults(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getTranslatedAssessmentType(
                                              item.assessmentType
                                            )
                                          ) + " "
                                        )
                                      ]
                                    )
                              ]
                            }
                          },
                          {
                            key: "item.completedAt",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      new Date(
                                        item.completedAt
                                      ).toLocaleDateString()
                                    ) + " "
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "item.score",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.getAssessmentRating(item)) + " "
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "footer",
                            fn: function() {
                              return [
                                !_vm.atEndOfAssessment
                                  ? _c(
                                      "v-row",
                                      {
                                        attrs: {
                                          justify: "center",
                                          "no-gutters": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              id: "loadMoreButton",
                                              text: "",
                                              color: "grey",
                                              small: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.batchStartIndex +=
                                                  _vm.assessmentBatchSize
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-decoration-underline text-none"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "wellness.v1.assessmentHistory.loadMore"
                                                    )
                                                  ) + " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        4259934600
                      )
                    },
                    [
                      _c(
                        "template",
                        { slot: "no-data" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "elevation-0 text-center",
                              attrs: { color: "transparent" }
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      contain: "",
                                      src: require("@/assets/images/undraw_no_data.svg"),
                                      "max-width": "150"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "wellness.v1.assessmentHistory.noData"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            {
              staticClass: "fill-height",
              attrs: { justify: "center", align: "center" }
            },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 50,
                  width: 5,
                  indeterminate: "",
                  color: "disabled"
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
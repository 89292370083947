<template>
  <v-card flat v-if="vertical" style="min-width: 266px">
    <div
      v-for="day in days"
      :key="day"
      class="d-flex justify-end noTextHighlight"
      style="width: 100%"
    >
      <div
        :id="'streakContainer' + day"
        :ref="'streakContainer' + day"
        v-if="day === today"
        class="mr-4 flip-box"
      >
        <div class="flip-box-inner">
          <v-img
            :src="require('@/assets/images/wellness/streakDayMedal.jpg')"
            :height="iconSize"
            contain
            ><div
              id="streakNumber"
              class="text-body-1 text-center"
              style="width: 100%; position: relative; top: 8%"
            >
              {{ streakNumber }}
            </div>
          </v-img>
        </div>
      </div>
      <div
        :id="'dateText' + day"
        class="mr-4 textOverflow text-end"
        :class="textClass(day)"
        style="min-width: 80px"
      >
        {{ getDay(day) }}
      </div>
      <div>
        <v-btn
          :id="'stepperButton' + day"
          icon
          :width="iconSize"
          :height="iconSize"
          @click="selectItem(day)"
          ><v-img
            :id="'stepperImage' + day"
            :src="
              require('@/assets/images/wellness/stepperIcons/' + getImage(day))
            "
            :width="iconSize"
            :height="iconSize"
            :class="day > today ? 'disabledBackground' : ''"
          >
          </v-img
        ></v-btn>
        <div
          v-if="day !== days.length"
          :class="day >= today ? 'dividerIncomplete' : 'dividerComplete'"
          style="
            position: relative;
            right: 20px;
            border-right: 1px solid grey;
            height: 50px;
            z-index: 3;
          "
        ></div>
      </div>
    </div>
  </v-card>
  <v-card v-else flat class="d-flex">
    <div
      v-for="day in days"
      :key="day"
      class="d-flex noTextHighlight"
      :class="day !== days.length ? 'flex-grow-1 flex-shrink-1' : ''"
    >
      <div class="d-flex flex-column justify-start align-center">
        <div>
          <v-btn
            :id="'stepperButton' + day"
            icon
            :width="iconSize"
            :height="iconSize"
            @click="selectItem(day)"
            ><v-img
              :id="'stepperImage' + day"
              :src="
                require('@/assets/images/wellness/stepperIcons/' +
                  getImage(day))
              "
              :width="iconSize"
              :height="iconSize"
              :class="day > today ? 'disabledBackground' : ''"
            >
            </v-img>
            <div
              v-if="day !== days.length"
              style="position: absolute; border-top: 1px solid grey; z-index: 3"
            ></div
          ></v-btn>
        </div>
        <div
          v-if="!$vuetify.breakpoint.xs"
          :id="'dateText' + day"
          class="textOverflow text-center mt-1"
          :class="textClass(day)"
          style="min-width: 80px"
        >
          {{ getDay(day) }}
        </div>
      </div>
      <v-divider
        v-if="day !== days.length"
        :key="day"
        class="mt-5"
        :class="day >= today ? 'dividerIncomplete' : 'dividerComplete'"
      />
    </div>
  </v-card>
</template>

<script>
import { EventBus } from "@/services/events/event-bus";
import { getPainCoachDayStreaks } from "@/customApi.js";

export default {
  data() {
    return {
      iconSize: 40,
      streakNumber: ""
    };
  },
  props: {
    value: Number,
    cycle: Object,
    vertical: Boolean,
    today: Number
  },
  mounted() {
    EventBus.$on("painCoachTaskCompleted", this.getDayStreak);
    this.getDayStreak();
  },
  beforeDestroy() {
    EventBus.$off("painCoachTaskCompleted");
  },
  computed: {
    days() {
      if (!this.cycle) {
        return [];
      }
      var days = Object.keys(this.cycle.tasks);
      return days.map(x => parseInt(x)).sort();
    }
  },
  methods: {
    selectItem(day) {
      this.$emit("input", day);
    },
    getDay(day) {
      return this.cycle.tasks[day][0].time;
    },
    findCycleTask(day, notNullProperty) {
      return this.cycle.tasks[day].find(x => !!x[notNullProperty]);
    },
    getImage(day) {
      var complete = this.findCycleTask(day, "completedAt");
      if (complete) {
        return day === this.value
          ? "number_" + day + "_active.svg"
          : "tick.svg";
      }

      return day === this.value
        ? "number_" + day + "_active.svg"
        : "number_" + day + "_inactive.svg";
    },
    textClass(day) {
      var complete = this.findCycleTask(day, "completedAt");
      let selectedItem = day === this.value;
      let textSize = selectedItem ? "text-h5" : "text-body-2";

      let textColour = "";
      if (complete) {
        textColour = "secondary--text";
      } else {
        textColour = day > this.today ? "disabled--text" : "black--text";
      }

      let topMargin = "";
      if (this.vertical) {
        topMargin = selectedItem ? "mt-1" : "mt-3";
      }

      return `${textSize} ${textColour} ${topMargin}`;
    },
    async getDayStreak() {
      try {
        let previousStreak = this.streakNumber;
        let streakNumber = await getPainCoachDayStreaks();
        this.streakNumber = streakNumber ?? "";
        if (previousStreak !== "" && streakNumber != previousStreak) {
          this.animateStreak();
        }
      } catch (ex) {
        this.$logger.captureException(ex);
      }
    },
    animateStreak() {
      let vm = this;
      this.$refs[`streakContainer${this.today}`][0].classList.add("spinning");
      setTimeout(() => {
        vm.$refs[`streakContainer${this.today}`][0].classList.remove(
          "spinning"
        );
      }, 1800);
    }
  }
};
</script>

<style scoped>
.textOverflow {
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
}
.disabledBackground {
  background-color: #e8e8e8;
  border-radius: 50%;
}

.dividerComplete {
  border-color: var(--v-secondary-base) !important;
}
.dividerIncomplete {
  border-color: var(--v-disabled-base) !important;
}

.flip-box {
  background-color: transparent;
  perspective: 1000px;
}
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
}
.flip-box.spinning .flip-box-inner {
  transition: transform 1.8s;
  transform: rotateY(720deg);
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      !_vm.hidePainCoach
        ? _c("pain-coach", {
            class: _vm.$vuetify.breakpoint.mdAndUp ? "px-4" : "px-0 mx-0",
            style:
              _vm.$vuetify.breakpoint.name === "xl"
                ? "margin: 0px 125px 0px 125px"
                : "",
            attrs: { userId: _vm.userId },
            on: {
              painArea: function($event) {
                _vm.painArea = $event
                _vm.painAreaLoaded = true
              }
            }
          })
        : _vm._e(),
      _vm.onboardUser
        ? _c("WellnessOnboardingTour", {
            attrs: { painArea: _vm.painArea, currentStep: _vm.tourStep },
            on: {
              step: function($event) {
                _vm.tourStep = $event
              }
            }
          })
        : _vm._e(),
      _vm.notificationDialog && !_vm.showWellnessTour
        ? _c(
            "v-dialog",
            {
              attrs: { width: _vm.notificationDialogWidth },
              model: {
                value: _vm.notificationDialog,
                callback: function($$v) {
                  _vm.notificationDialog = $$v
                },
                expression: "notificationDialog"
              }
            },
            [
              _c("notification-dialog", {
                model: {
                  value: _vm.notificationDialog,
                  callback: function($$v) {
                    _vm.notificationDialog = $$v
                  },
                  expression: "notificationDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.webcamQuickAssess
        ? _c(
            "v-dialog",
            {
              model: {
                value: _vm.webcamQuickAssess,
                callback: function($$v) {
                  _vm.webcamQuickAssess = $$v
                },
                expression: "webcamQuickAssess"
              }
            },
            [
              _c("quick-assess", {
                attrs: { type: "webcamAnalysis" },
                model: {
                  value: _vm.webcamQuickAssess,
                  callback: function($$v) {
                    _vm.webcamQuickAssess = $$v
                  },
                  expression: "webcamQuickAssess"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.simsQuickAssess
        ? _c(
            "v-dialog",
            {
              attrs: { fullscreen: _vm.$vuetify.breakpoint.xs },
              model: {
                value: _vm.simsQuickAssess,
                callback: function($$v) {
                  _vm.simsQuickAssess = $$v
                },
                expression: "simsQuickAssess"
              }
            },
            [
              _c("quick-assess", {
                attrs: { type: "deskSetup" },
                model: {
                  value: _vm.simsQuickAssess,
                  callback: function($$v) {
                    _vm.simsQuickAssess = $$v
                  },
                  expression: "simsQuickAssess"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.feedbackDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.smAndDown ? "100%" : "40%",
                persistent: ""
              },
              model: {
                value: _vm.feedbackDialog,
                callback: function($$v) {
                  _vm.feedbackDialog = $$v
                },
                expression: "feedbackDialog"
              }
            },
            [
              _c("feedback-dialog", {
                model: {
                  value: _vm.feedbackDialog,
                  callback: function($$v) {
                    _vm.feedbackDialog = $$v
                  },
                  expression: "feedbackDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showPainCoachStatsFeaturePopup
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.painManagementFeaturePopup,
                callback: function($$v) {
                  _vm.painManagementFeaturePopup = $$v
                },
                expression: "painManagementFeaturePopup"
              }
            },
            [
              _c("pain-coach-intro-dialog", {
                model: {
                  value: _vm.painManagementFeaturePopup,
                  callback: function($$v) {
                    _vm.painManagementFeaturePopup = $$v
                  },
                  expression: "painManagementFeaturePopup"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.assessmentOpen
        ? _c("feedback-button", {
            style: _vm.$vuetify.breakpoint.smAndDown ? "" : "top:50%;right: 0%",
            model: {
              value: _vm.feedbackDialog,
              callback: function($$v) {
                _vm.feedbackDialog = $$v
              },
              expression: "feedbackDialog"
            }
          })
        : _vm._e(),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.mdAndUp ? "px-4" : "px-0 mx-0",
          style:
            _vm.$vuetify.breakpoint.name === "xl"
              ? "margin: 0px 125px 0px 125px"
              : "",
          attrs: { justify: "start", align: "center" }
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("p", { staticClass: "text-h4 ml-3 mb-0" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("wellness.v1.latestAssessment.title")) + " "
              )
            ])
          ]),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                sm: "6",
                md: "6",
                lg: "3",
                xl: "3",
                id: "v-step-2"
              }
            },
            [
              _c("LastestAssessmentSummary", {
                attrs: {
                  assessment: _vm.latestCompleteDeskAssessment,
                  isDeskAssessment: true,
                  assessmentsLoading: _vm.assessmentsLoading
                },
                on: {
                  QuickAssess: function($event) {
                    return _vm.openQuickAssess($event)
                  }
                }
              })
            ],
            1
          ),
          !_vm.hideBurnoutCompletely
            ? _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "6", lg: "3", xl: "3" } },
                [
                  _c("LastestAssessmentSummary", {
                    attrs: {
                      assessment: _vm.latestCompleteBurnoutAssessment,
                      isDeskAssessment: false,
                      assessmentsLoading: _vm.assessmentsLoading
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              attrs: { cols: "12", lg: _vm.hideBurnoutCompletely ? "9" : "6" }
            },
            [
              _c("WellnessAssessmentTable", {
                attrs: { assessments: _vm.assessments },
                model: {
                  value: _vm.assessmentOpen,
                  callback: function($$v) {
                    _vm.assessmentOpen = $$v
                  },
                  expression: "assessmentOpen"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("WellnessRecommendations", {
                attrs: {
                  burnoutAssessment: _vm.burnoutResult,
                  deskAssessment: _vm.deskAssessmentResult,
                  assessmentsLoading: _vm.recommendationsLoading
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c(
          "p",
          {
            staticClass: "text-h4 ml-3",
            class: _vm.isDefaultCycle || _vm.showError ? "mb-0" : ""
          },
          [_vm._v(" " + _vm._s(_vm.$t("wellness.v2.title")) + " ")]
        )
      ]),
      _vm.showError
        ? _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c(
                "v-alert",
                { attrs: { id: "errorMessage", type: "error", text: "" } },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.$t("genericError")) }
                  })
                ]
              )
            ],
            1
          )
        : _vm.isDefaultCycle
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.isDefaultCycle && !_vm.showError
                ? _c(
                    "v-alert",
                    {
                      attrs: { id: "defaultMessage", type: "info", text: true }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "mr-2 text-decoration-underline primary--text",
                          staticStyle: { cursor: "pointer" },
                          attrs: { id: "startDeskAssessment" },
                          on: {
                            click: function($event) {
                              return _vm.startDeskAssessment()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("wellness.v2.completeAssessment"))
                          )
                        ]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("wellness.v2.toGetPersonalisedProgramme")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm.highRiskWarning
        ? _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c(
                "v-alert",
                { attrs: { id: "highRiskWarning", type: "warning", text: "" } },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.highRiskWarning) }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { cols: _vm.painCoachCols, id: "v-step-0" } },
        [
          _c("pain-coach-cycle", {
            attrs: { painArea: _vm.painArea },
            on: {
              cycle: function($event) {
                _vm.cycle = $event
              },
              error: function($event) {
                _vm.showError = true
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        {
          class: _vm.$vuetify.breakpoint.lgAndUp ? "my-3" : "",
          attrs: { id: "v-step-1", cols: _vm.wellnessStatsCols }
        },
        [
          _vm.showLoader
            ? _c("v-skeleton-loader", {
                attrs: { id: "statsLoader", type: "image" }
              })
            : _c("wellness-stats-card", {
                attrs: {
                  userId: _vm.userId,
                  userGoal: _vm.goal,
                  userStats: _vm.stats
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <v-btn
      v-if="showNextDay"
      @click="nextDay"
      outlined
      color="primary"
      class="mb-3"
      :loading="update"
      >NEXT DAY</v-btn
    >
    <v-row v-if="!cycle" justify="center" id="loader">
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto">
        <v-skeleton-loader
          type="avatar, list-item,avatar, list-item,avatar, list-item,avatar"
        />
      </v-col>
      <v-col v-else cols="3" sm="2" v-for="i in stepperLoaderCount" :key="i">
        <v-skeleton-loader type="avatar" />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12">
        <v-skeleton-loader type="image, article" />
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="5">
        <v-skeleton-loader type="image, article" />
      </v-col>
    </v-row>
    <v-card
      v-if="cycle"
      flat
      :disabled="isDefault"
      :class="isDefault ? 'greyScale' : ''"
    >
      <v-row no-gutters justify="start">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : '12'"
          ><wellness-stepper
            :class="$vuetify.breakpoint.smAndDown ? 'mb-5' : 'mr-8'"
            :cycle="cycle"
            v-model="currentSelectedStepperDay"
            :vertical="$vuetify.breakpoint.mdAndUp"
            :today="today"
        /></v-col>
        <v-col cols="12" :sm="exercises.length === 1 ? 6 : 12" md=""
          ><pain-coach-exercises
            :exercises="exercises"
            :firstTimeUser="cycle ? cycle.firstTimeUser : false"
            :painArea="painArea"
        /></v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import PainCoachExercises from "@/components/dashboard/wellness/PainCoachExercises.vue";
import WellnessStepper from "@/components/dashboard/wellness/WellnessStepper.vue";
import {
  getPainCoachCycle,
  markWellnessTasksAsSeen,
  shiftWellnessProgramme
} from "@/customApi.js";
import { mapGetters } from "vuex";
import { EventBus } from "@/services/events/event-bus";

export default {
  name: "PainCoachCycle",
  components: {
    PainCoachExercises,
    WellnessStepper
  },
  created() {
    this.getCycle();
  },
  mounted() {
    EventBus.$on("setPainCoachCycleToLoad", this.setToLoad);
    EventBus.$on("updatePainCoachCycle", this.getCycle);
  },
  beforeDestroy() {
    EventBus.$off("setPainCoachCycleToLoad");
    EventBus.$off("updatePainCoachCycle");
  },
  props: {
    painArea: Object
  },
  data() {
    return {
      cycle: null,
      today: null,
      currentSelectedStepperDay: null,
      isDefault: false,
      update: false
    };
  },
  computed: {
    ...mapGetters(["userEmail", "showNextDayButton"]),
    exercises() {
      if (!this.cycle || this.currentSelectedStepperDay === null) {
        return [];
      }
      return this.cycle.tasks[this.currentSelectedStepperDay];
    },
    stepperLoaderCount() {
      return this.$vuetify.breakpoint.xs ? 4 : 5;
    },
    showNextDay() {
      return (
        !!process.env.NODE_ENV &&
        !process.env.NODE_ENV.includes("production") &&
        this.showNextDayButton
      );
    }
  },
  methods: {
    setToLoad() {
      this.cycle = null;
    },
    async getCycle() {
      try {
        var cycle = await getPainCoachCycle();
        this.$emit("cycle", cycle);
        this.today = this.getToday(cycle);
        this.cycle = this.assignTaskLabels(cycle);
        this.currentSelectedStepperDay = this.today;
        this.isDefault = cycle.isDefaultCycle;
      } catch (err) {
        this.$logger.captureException(err);
        this.$emit("error");
      } finally {
        this.markTodayTasksAsSeen();
      }
    },
    getDays(cycle) {
      return Object.keys(cycle.tasks)
        .map(x => parseInt(x))
        .sort();
    },
    getToday(cycle) {
      let days = this.getDays(cycle);
      let today = days.find(day => this.areTodayTasks(cycle.tasks[day]));
      if (today === null) {
        return Math.max.apply(null, days);
      }
      return today;
    },
    areTodayTasks(tasks) {
      return tasks.every(
        a => a.seenAt === null || this.isToday(new Date(a.seenAt))
      );
    },
    assignTaskLabels(cycle) {
      var days = this.getDays(cycle);

      days.forEach(day => {
        cycle.tasks[day].forEach(task => this.assignLabel(task, day));
      });

      return cycle;
    },
    assignLabel(task, day) {
      if (day === this.today) {
        task.time = this.$t("wellness.v2.timeLabels.today");
        task.locked = false;
        return;
      }

      if (!task.seenAt) {
        var daysToAdd = day - this.today;
        var date = new Date(Date.now() + 86400000 * daysToAdd);
        task.time = this.dateToMonthString(date);
        task.locked = true;
      } else {
        var date = new Date(task.seenAt);
        task.time = this.dateToMonthString(date);
        task.locked = false;
      }
    },
    dateToMonthString(date) {
      return date.toLocaleString(this._i18n.locale, {
        day: "numeric",
        month: "long"
      });
    },
    isToday(utcDate) {
      if (!utcDate) {
        return false;
      }
      const today = new Date(Date.now());
      return (
        utcDate.getDate() === today.getUTCDate() &&
        utcDate.getMonth() === today.getUTCMonth() &&
        utcDate.getFullYear() === today.getUTCFullYear()
      );
    },
    async markTodayTasksAsSeen() {
      if (!this.cycle || this.isDefault) {
        return;
      }
      let tasks = Object.values(this.cycle.tasks);
      let todayTasks = tasks.find(x => this.areTodayTasks(x));
      try {
        if (todayTasks.every(x => x.seenAt)) {
          return;
        }
        let taskDtos = todayTasks.map(x => {
          return {
            name: x.name,
            userProgrammeId: x.userProgrammeId,
            type: x.type,
            taskId: x.taskId
          };
        });

        var updatedTasks = await markWellnessTasksAsSeen(taskDtos);

        this.cycle.tasks[this.today].forEach(task => {
          var matchingTask = updatedTasks.find(updated => {
            return updated.taskId == task.taskId;
          });

          if (!matchingTask) {
            throw new Error(
              "Could not find matching task when updating seen at"
            );
          }
          task.id = matchingTask.id;
          task.seenAt = matchingTask.seenAt;
          task.completedAt = matchingTask.completedAt;

          this.assignLabel(task, this.today);
        });
      } catch (err) {
        this.$logger.captureException(err);
      }
    },
    async nextDay() {
      this.update = true;
      await shiftWellnessProgramme(this.userEmail);
      await this.getCycle();
      this.update = false;
    }
  }
};
</script>

<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>

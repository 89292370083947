var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-3", attrs: { rounded: "lg" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "center" } },
        [
          _c("v-col", { staticClass: "text-h5 my-3 text-center" }, [
            _vm._v(_vm._s(_vm.$t("wellness.v2.introductionDialog.title")))
          ]),
          _c(
            "v-col",
            { staticClass: "mt-3", attrs: { cols: "12" } },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/images/wellness/painCoachStats.png"),
                  contain: "",
                  height: "300px"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "my-5 text-body-1", attrs: { cols: "12" } },
            [
              _c("v-row", { attrs: { justify: "center", "no-gutters": "" } }, [
                _c("ul", [
                  _c("li", { staticClass: "my-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("wellness.v2.introductionDialog.goal")) +
                        " "
                    )
                  ]),
                  _c("li", { staticClass: "my-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("wellness.v2.introductionDialog.exercises")
                        ) +
                        " "
                    )
                  ]),
                  _c("li", { staticClass: "my-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("wellness.v2.introductionDialog.progress")
                        ) +
                        " "
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mb-4", attrs: { xs: "10", sm: "8", md: "4" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { width: "100%", rounded: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("input", false)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("wellness.v2.wellnessTour.button")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
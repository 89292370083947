var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-h4 ml-3 mt-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("wellness.v1.recommendations.title")) + " ")
      ]),
      _c(
        "v-row",
        { staticClass: "ml-3", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.name === "xl" ? "py-0" : "pb-0",
              attrs: { cols: "12", xl: "8" }
            },
            [
              _c(
                "v-chip-group",
                {
                  attrs: { column: "", multiple: "" },
                  model: {
                    value: _vm.selectedRecommendations,
                    callback: function($$v) {
                      _vm.selectedRecommendations = $$v
                    },
                    expression: "selectedRecommendations"
                  }
                },
                _vm._l(Object.values(_vm.recommendationOptions), function(
                  group
                ) {
                  return _c(
                    "v-chip",
                    {
                      key: group.name,
                      staticClass: "chipSelector text-body-1 mr-4",
                      attrs: {
                        id: group.name,
                        value: group.name,
                        color: group.color,
                        outlined: !_vm.isSelected(group.name),
                        "text-color":
                          group.name === _vm.recommendationOptions.all.name &&
                          _vm.isSelected(group.name)
                            ? "white"
                            : ""
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "wellness.v1.recommendations.options." +
                                group.name
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", xl: "4" } },
            [
              _vm.isSelected(_vm.recommendationOptions.desk.name) ||
              _vm.isSelected(_vm.recommendationOptions.all.name)
                ? _c(
                    "v-tabs",
                    {
                      attrs: {
                        right: _vm.$vuetify.breakpoint.name === "xl",
                        left: _vm.$vuetify.breakpoint.name !== "xl",
                        "icons-and-text": "",
                        color: "primary",
                        height: "50px"
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          staticClass: "permanentTabBorder",
                          attrs: { href: "#budget" }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "transparent" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("attach_money")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1027233249
                              )
                            },
                            [
                              _c("vitrue-tooltip", {
                                attrs: {
                                  message: _vm.$t(
                                    "tooltips.budgetRecommendation"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "permanentTabBorder",
                          attrs: { href: "#upgrade" }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "transparent" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("attach_money")
                                            ]),
                                            _c("v-icon", [
                                              _vm._v("attach_money")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                322517926
                              )
                            },
                            [
                              _c("vitrue-tooltip", {
                                attrs: {
                                  message: _vm.$t(
                                    "tooltips.upgradeRecommendation"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.assessmentsLoading && _vm.noRecommendations
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-progress-circular", {
                attrs: {
                  id: "loadingSymbol",
                  size: 50,
                  width: 5,
                  indeterminate: "",
                  color: "disabled"
                }
              })
            ],
            1
          )
        : _vm.noRecommendations
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "p",
                  {
                    staticClass: "text-h5 mt-6 text-center",
                    attrs: { id: "noRecommendationsText" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "wellness.v1.recommendations.noRecommendations"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ],
            1
          )
        : _c(
            "div",
            _vm._l(_vm.recommendationsToShow, function(group, index) {
              return _c(
                "v-row",
                { key: "group" + index, attrs: { align: "center" } },
                _vm._l(group, function(card) {
                  return _c(
                    "v-col",
                    {
                      key: card.id,
                      staticClass: "align-self-stretch px-0",
                      class: card.impact ? "pt-5" : "",
                      attrs: {
                        cols: "12",
                        md: "6",
                        lg: card.json.type === "exercise" ? "6" : "4",
                        xl: card.json.type === "exercise" ? "4" : "3"
                      }
                    },
                    [
                      _c("recommendations-card", {
                        staticClass: "ma-4",
                        staticStyle: { height: "100%" },
                        attrs: {
                          id: card.id,
                          linkType: card.linkType(_vm.combinedResults),
                          assessmentId: _vm.getAssessmentId(card.json.type),
                          imageName: card.json.imageName,
                          heading: card.heading
                            ? card.heading(_vm.combinedResults)
                            : _vm.$t(card.json.heading),
                          subheading: _vm.$t(card.json.subheading),
                          explanation: card.explanation
                            ? card.explanation(_vm.combinedResults)
                            : _vm.$t(card.json.explanation),
                          type: card.json.type,
                          link: _vm.linkToShow(card),
                          emailLink: card.json.emailLink,
                          route: card.json.route,
                          previouslyLiked: _vm.likedCards.includes(card.id),
                          previouslyRequested: _vm.previouslyRequested.includes(
                            card.id
                          ),
                          coveredBy: card.json.coveredBy,
                          impact: card.impact,
                          videoDisclaimer: _vm.videoDisclaimer,
                          deskAssessment: _vm.deskAssessment
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
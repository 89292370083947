var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "text-center rounded-lg elevation-0",
      attrs: { color: "vitruePaleGrey", height: _vm.cardHeight }
    },
    [
      !_vm.assessmentsLoading
        ? _c(
            "v-row",
            { staticClass: "fill-height", attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                {
                  style: "height:" + _vm.cardHeight / 2 + "px",
                  attrs: { cols: "12" }
                },
                [
                  !_vm.isDeskAssessment
                    ? _c("v-img", {
                        staticClass: "mb-2",
                        attrs: {
                          src: _vm.burnoutImage,
                          height: _vm.gaugeWidth / 2 + 10,
                          contain: ""
                        }
                      })
                    : _vm._e(),
                  !_vm.isDeskAssessment
                    ? _c(
                        "p",
                        {
                          staticClass: "text-h6",
                          class: _vm.burnoutScoreColor + "--text",
                          attrs: { id: "score" }
                        },
                        [_vm._v(" " + _vm._s(_vm.burnoutScore) + " ")]
                      )
                    : _c("DeskAssessmentResultVisual", {
                        attrs: {
                          gaugeWidth: _vm.gaugeWidth,
                          score: !!_vm.assessment ? _vm.assessment.score : 50,
                          disabled: !_vm.assessment
                        }
                      })
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12", "align-self": "end" } }, [
                _c(
                  "p",
                  {
                    staticClass: "text-h6 font-weight-bold mb-0",
                    attrs: { id: "title" }
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _vm.assessment
                  ? _c(
                      "p",
                      {
                        staticClass: "text-body-2 disabled--text mb-0",
                        attrs: { id: "lastResult" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "wellness.v1.latestAssessment.lastResult",
                                { "0": _vm.daysSinceLastResult }
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm.isBurnoutAndIsDisabled
                  ? _c(
                      "p",
                      {
                        staticClass: "text-body-2 accent--text mb-0",
                        attrs: { id: "noAccessText" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("wellness.v1.latestAssessment.noAccess")
                            ) +
                            " "
                        )
                      ]
                    )
                  : _c(
                      "p",
                      {
                        staticClass: "text-body-2 disabled--text mb-0",
                        attrs: { id: "noResultsText" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("wellness.v1.latestAssessment.noResults")
                            ) +
                            " "
                        )
                      ]
                    )
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.isDeskAssessment
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: "",
                            disabled: !_vm.disableAssessments,
                            color: "transparent"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "pr-0 text-h6 text-none",
                                            attrs: {
                                              id: "deskAssessmentStartTest",
                                              color: "primary",
                                              "min-width": "200px",
                                              disabled: _vm.disableAssessments
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.startNewAssessment()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mr-2" },
                                              [_vm._v("laptop_chromebook")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "wellness.v1.latestAssessment.testNow"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-menu",
                                              {
                                                attrs: { "offset-x": "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "ml-2 px-0 elevation-0 rounded-l-0",
                                                                  attrs: {
                                                                    id:
                                                                      "showMenu",
                                                                    color:
                                                                      "primary lighten-1",
                                                                    disabled:
                                                                      _vm.disableAssessments
                                                                  }
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    large: "",
                                                                    color:
                                                                      "primary"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-menu-down"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        attrs: {
                                                          id: "webcamAnalysis"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openQuickAssessment(
                                                              "webcamAnalysis"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "wellness.v1.latestAssessment.deskAssessment.webcamAssessment"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openQuickAssessment(
                                                              "deskSetup"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "wellness.v1.latestAssessment.deskAssessment.deskPlanner"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1602358449
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: {
                              message: _vm.$t("tooltips.trialOrSubHasExpired")
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: "",
                            disabled: !_vm.isBurnoutAndIsDisabled,
                            color: "transparent"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b({}, "div", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "text-none text-h6",
                                            attrs: {
                                              id: "burnoutStartTest",
                                              color: "accent",
                                              disabled:
                                                _vm.isBurnoutAndIsDisabled,
                                              "min-width": "200px"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.startNewAssessment()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mr-2" },
                                              [_vm._v("mdi-lightning-bolt")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "wellness.v1.latestAssessment.testNow"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            619129445
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: {
                              message: _vm.disableAssessments
                                ? _vm.$t("tooltips.trialOrSubHasExpired")
                                : _vm.$t(
                                    "wellness.v1.latestAssessment.noAccess"
                                  )
                            }
                          })
                        ],
                        1
                      ),
                  _c(
                    "p",
                    {
                      staticClass: "text-body-2 disabled--text mt-3",
                      attrs: { id: "title" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("wellness.v1.latestAssessment.reassess")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            {
              staticClass: "fill-height",
              attrs: { justify: "center", align: "center" }
            },
            [
              _c("v-progress-circular", {
                attrs: {
                  id: "loadingCircle",
                  size: 50,
                  width: 5,
                  indeterminate: "",
                  color: "disabled"
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
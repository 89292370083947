<template>
  <v-card
    id="statsCard"
    class="rounded-lg py-1 px-4 d-flex flex-column"
    :class="!hasGoal ? 'greyScale' : ''"
    flat
    color="vitruePaleGrey"
    height="100%"
    :min-height="horizontalLayout ? '' : '375'"
    :disabled="!hasGoal"
  >
    <v-dialog
      content-class="dialogOverflow"
      v-if="changeGoal"
      v-model="changeGoal"
      width="75%"
    >
      <vitrue-dialog
        v-model="changeGoal"
        image="wellness/changeGoal.png"
        :title="$t('painAssessment.changeGoalTitle')"
      >
        <change-goal-dialog
          class="modalStyle"
          @close="changeGoal = false"
          :userId="userId"
          :goal="goalAsInt"
          @updatePainArea="
            changeGoal = false;
            openPainCheckinDialog();
          "
        />
      </vitrue-dialog>
    </v-dialog>

    <v-dialog
      content-class="dialogOverflow"
      v-if="painCheckinDialog"
      v-model="painCheckinDialog"
      :width="$vuetify.breakpoint.mdAndDown || highZoom ? '80%' : '60%'"
    >
      <vitrue-dialog
        v-model="painCheckinDialog"
        v-if="painCheckinDialog"
        image="wellness/painCheckin.svg"
        :title="$t('painCheckin.title')"
        :hideCloseButton="hidePainCheckinCloseButton"
      >
        <pain-checkin
          :existingPainArea="painArea.area"
          @close="painCheckinDialog = false"
          @hideCloseButton="hidePainCheckinCloseButton = true"
        />
      </vitrue-dialog>
    </v-dialog>

    <v-card-title class="text-h6 mb-0 black--text">{{
      $t("wellness.v2.statsCard.title")
    }}</v-card-title>
    <v-row no-gutters class="text-center" justify="space-around">
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        :cols="goalsAndStatusColumnSize"
        align-self="center"
      >
        <v-row no-gutters justify="space-around"
          ><v-col
            :cols="showPainLevel ? 6 : 12"
            id="goalTitle"
            class="mb-1 text-body-2 vitrueGrey--text"
          >
            {{ $t("wellness.v2.statsCard.goals.title") }}</v-col
          >
          <v-col
            cols="6"
            v-if="showPainLevel"
            id="statusTitle"
            class="mb-1 text-body-2 vitrueGrey--text"
          >
            {{
              $t(
                `wellness.v2.statsCard.status.title.${
                  goal === "ReducePain" ? "painGoal" : "otherGoal"
                }`
              )
            }}
          </v-col></v-row
        >
        <v-row no-gutters justify="space-around"
          ><v-col
            :cols="showPainLevel ? 6 : 12"
            id="goalText"
            class="mb-2 text-body-1"
            @click="openChangeGoalDialog()"
            style="cursor: pointer"
          >
            {{ goalText }}
            <span>
              <v-btn icon color="primary" x-small
                ><v-icon>mdi-pencil-outline</v-icon></v-btn
              >
            </span></v-col
          >
          <v-col
            cols="6"
            v-if="showPainLevel"
            id="painText"
            class="mb-2 text-body-1"
            @click="openPainCheckinDialog()"
            style="cursor: pointer"
          >
            {{ painText }}
            <span
              ><v-btn icon color="primary" x-small
                ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
              >
            </span>
          </v-col></v-row
        >
        <v-row no-gutters justify="space-around"
          ><v-col :cols="showPainLevel ? 6 : 12"
            ><v-row justify="center" no-gutters>
              <div>
                <v-img
                  @click="openChangeGoalDialog()"
                  style="cursor: pointer"
                  class="rounded-circle zoom"
                  contain
                  width="120"
                  height="120"
                  :src="require(`@/assets/images/${wellnessGoalImage}`)"
                />
              </div> </v-row
          ></v-col>
          <v-col cols="6" v-if="showPainLevel"
            ><v-row justify="center" no-gutters>
              <v-progress-circular
                class="zoom"
                @click="openPainCheckinDialog()"
                style="cursor: pointer"
                rotate="270"
                size="120"
                :value="painArea.level * 10"
                width="8"
                :color="painLevelColour"
                ><v-row align="end"
                  ><span id="painLevel" class="text-h3">{{
                    painArea.level
                  }}</span
                  ><span class="text-h6 disabled--text">/10</span></v-row
                ></v-progress-circular
              >
            </v-row></v-col
          ></v-row
        >
      </v-col>
      <v-col
        id="goalSection"
        v-if="$vuetify.breakpoint.xs"
        cols="12"
        class="mb-4"
        :align-self="$vuetify.breakpoint.xl ? 'center' : 'start'"
        ><p id="goalTitle" class="mb-1 text-body-2 vitrueGrey--text">
          {{ $t("wellness.v2.statsCard.goals.title") }}
        </p>
        <p
          id="goalText"
          class="mb-2 text-body-1"
          @click="openChangeGoalDialog()"
          style="cursor: pointer"
        >
          {{ goalText }}
          <span>
            <v-btn icon color="primary" x-small
              ><v-icon>mdi-pencil-outline</v-icon></v-btn
            >
          </span>
        </p>
        <v-row justify="center" no-gutters>
          <div>
            <v-img
              @click="openChangeGoalDialog()"
              style="cursor: pointer"
              class="rounded-circle zoom"
              contain
              width="120"
              height="120"
              :src="require(`@/assets/images/${wellnessGoalImage}`)"
            />
          </div>
        </v-row>
      </v-col>
      <v-col
        id="statusSection"
        v-if="showPainLevel && $vuetify.breakpoint.xs"
        cols="12"
        class="mb-4"
        :align-self="$vuetify.breakpoint.xl ? 'center' : 'start'"
        ><p id="statusTitle" class="mb-1 text-body-2 vitrueGrey--text">
          {{
            $t(
              `wellness.v2.statsCard.status.title.${
                goal === "ReducePain" ? "painGoal" : "otherGoal"
              }`
            )
          }}
        </p>
        <p
          id="painText"
          class="mb-2 text-body-1"
          @click="openPainCheckinDialog()"
          style="cursor: pointer"
        >
          {{ painText }}
          <span
            ><v-btn icon color="primary" x-small
              ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
            >
          </span>
        </p>
        <v-row justify="center" no-gutters>
          <v-progress-circular
            class="zoom"
            @click="openPainCheckinDialog()"
            style="cursor: pointer"
            rotate="270"
            size="120"
            :value="painArea.level * 10"
            width="8"
            :color="painLevelColour"
            ><v-row align="end"
              ><span id="painLevel" class="text-h3">{{ painArea.level }}</span
              ><span class="text-h6 disabled--text">/10</span></v-row
            ></v-progress-circular
          >
        </v-row></v-col
      >
      <v-col
        id="statsSection"
        :cols="statsSectionColumnSize"
        :align-self="horizontalLayout ? 'center' : 'end'"
        class="text-start"
        ><v-row
          :justify="$vuetify.breakpoint.xs ? 'space-between' : 'space-around'"
          no-gutters
          align="stretch"
        >
          <v-col
            v-for="(stat, index) in stats"
            :key="index"
            :cols="individualStatColumnSize"
            class="px-2"
            ><v-divider> </v-divider>
            <v-row
              class="text-h6 mb-0"
              no-gutters
              justify="start"
              :class="statColor(stat.count, stat.trend)"
            >
              <v-col :id="`${stat.id}Count`" class="text-h5 pr-1" cols="auto">{{
                stat.count
              }}</v-col>
              <v-col>
                <div
                  :ref="`trendImageContainer${stat.id}`"
                  class="fill-height flip-box"
                >
                  <div class="flip-box-inner">
                    <v-row class="fill-height" align="center" no-gutters
                      ><v-img
                        :id="`${stat.id}Image`"
                        contain
                        max-width="15px"
                        :src="
                          require('@/assets/images/' + trendImage(stat.trend))
                        "
                    /></v-row>
                  </div>
                </div>
              </v-col>
            </v-row>

            <p :id="stat.id + 'Text'" class="text-body-2">
              {{ stat.description }}
            </p></v-col
          >
        </v-row></v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import WellnessGoalImages from "@/assets/json/wellness/WellnessGoalImages.json";
import WellnessGoals from "@/assets/json/wellness/WellnessGoals.json";
import PainLevelColours from "@/assets/json/common/PainLevelColours.json";
const ChangeGoalDialog = () =>
  import("@/components/dashboard/wellness/v2/ChangeGoal.vue");
const PainCheckin = () =>
  import("@/components/dashboard/wellness/v2/PainCheckin.vue");
const VitrueDialog = () => import("@/components/common/VitrueDialog.vue");

export default {
  components: {
    ChangeGoalDialog,
    PainCheckin,
    VitrueDialog
  },
  data() {
    return {
      changeGoal: false,
      painCheckinDialog: false,
      defaultStats: {
        weekStreak: { count: 0, trend: 0 },
        minutesActive: { count: 0, trend: 0 },
        totalExercises: { count: 0, trend: 0 }
      },
      defaultGoal: {
        goal: "ImproveWellbeing",
        painArea: { area: null, level: 0 }
      },
      hidePainCheckinCloseButton: false
    };
  },
  props: {
    userId: String,
    userGoal: Object,
    userStats: Object
  },
  watch: {
    userStats: {
      handler(newValue, oldValue) {
        if (
          oldValue.weekStreak.trend !== newValue.weekStreak.trend ||
          oldValue.weekStreak.count !== newValue.weekStreak.count
        ) {
          this.animateTrend("weekStreak");
        }
        if (
          oldValue.minutesActive.trend !== newValue.minutesActive.trend ||
          oldValue.minutesActive.count !== newValue.minutesActive.count
        ) {
          this.animateTrend("minutesActive");
        }
        if (
          oldValue.totalExercises.trend !== newValue.totalExercises.trend ||
          oldValue.totalExercises.count !== newValue.totalExercises.count
        ) {
          this.animateTrend("totalExercises");
        }
      },
      deep: true
    }
  },
  computed: {
    highZoom() {
      return window.devicePixelRatio > 1;
    },
    hasGoal() {
      return !!this.userGoal?.goal;
    },
    goal() {
      return this.hasGoal ? this.userGoal.goal : this.defaultGoal.goal;
    },
    painArea() {
      return this.userGoal?.painArea
        ? this.userGoal.painArea
        : this.defaultGoal.painArea;
    },
    goalAsInt() {
      return WellnessGoals[this.goal];
    },
    stats() {
      let statsToShow = this.userStats ?? this.defaultStats;
      return [
        {
          id: "weekStreak",
          count: statsToShow.weekStreak.count,
          trend: statsToShow.weekStreak.trend,
          description: this.$t("wellness.v2.statsCard.stats.weekStreak")
        },
        {
          id: "minutesActive",
          count: statsToShow.minutesActive.count,
          trend: statsToShow.minutesActive.trend,
          description: this.$t("wellness.v2.statsCard.stats.minutesActive")
        },
        {
          id: "totalExercises",
          count: statsToShow.totalExercises.count,
          trend: statsToShow.totalExercises.trend,
          description: this.$tc(
            "wellness.v2.statsCard.stats.exercises",
            statsToShow.totalExercises.count
          )
        }
      ];
    },
    goalsAndStatusColumnSize() {
      return this.horizontalLayout ? (this.showPainLevel ? 8 : 4) : 12;
    },
    statsSectionColumnSize() {
      if (this.$vuetify.breakpoint.xs) {
        return 10;
      }
      return this.horizontalLayout ? 4 : 12;
    },
    individualStatColumnSize() {
      if (this.$vuetify.breakpoint.xs) {
        return 5;
      }
      return this.horizontalLayout ? 10 : 3;
    },
    horizontalLayout() {
      return (
        (!this.showPainLevel && this.$vuetify.breakpoint.xl) ||
        (this.showPainLevel && this.$vuetify.breakpoint.lg) ||
        this.$vuetify.breakpoint.md
      );
    },
    painLevelColour() {
      return PainLevelColours[this.painArea.level];
    },
    showPainLevel() {
      return !!this.painArea.area;
    },
    wellnessGoalImage() {
      if (this.goal === "ReducePain") {
        let pictureArea = !!this.painArea.area ? this.painArea.area : "noArea";
        return WellnessGoalImages.ReducePain[pictureArea];
      }
      return WellnessGoalImages[this.goal];
    },
    goalText() {
      if (this.goal === "ReducePain") {
        return this.$t("wellness.v2.statsCard.goals.texts.pain", {
          0: this.painArea.area
            ? this.$t(
                `wellness.v2.statsCard.painAreas.${this.painArea.area}`
              ).toLowerCase()
            : ""
        });
      }

      return this.$t(`wellness.v2.statsCard.goals.texts.${this.goal}`);
    },
    painText() {
      if (this.painArea.area && this.goal === "ReducePain") {
        return this.$t("wellness.v2.statsCard.status.text.genericPain");
      }

      let painAreaTranslation = this.$t(
        `wellness.v2.statsCard.painAreas.${this.painArea.area}`
      );

      return this.$t("wellness.v2.statsCard.status.text.specificPain", {
        0: painAreaTranslation
      });
    }
  },
  methods: {
    trendImage(trend) {
      if (!trend) {
        return "defaultTrendArrow.svg";
      }
      return trend > 0 ? "upwardsTrendArrow.svg" : "downwardsTrendArrow.svg";
    },
    statColor(count, trend) {
      if (!count || trend === 0) {
        return "vitrueGrey--text";
      }
      return trend > 0 ? "secondary--text" : "vitrueRed--text";
    },
    animateTrend(id) {
      let vm = this;
      this.$refs[`trendImageContainer${id}`][0].classList.add("spinning");
      setTimeout(() => {
        vm.$refs[`trendImageContainer${id}`][0].classList.remove("spinning");
      }, 1800);
    },
    openChangeGoalDialog() {
      this.changeGoal = true;
      this.$gtag.event("Open Change Goal Dialog", {
        event_category: "Wellness Stats Card"
      });
    },
    openPainCheckinDialog() {
      this.painCheckinDialog = true;
      this.$gtag.event("Open Pain Check in Dialog", {
        event_category: "Wellness Stats Card"
      });
    }
  }
};
</script>

<style>
.zoom {
  transition: all 0.2s ease-in-out;
}
.zoom:hover {
  transform: scale(1.1);
}

.flip-box {
  background-color: transparent;
  perspective: 1000px;
}
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
}
.flip-box.spinning .flip-box-inner {
  transition: transform 1.8s;
  transform: rotateX(720deg);
}
.modalStyle {
  overflow: auto;
  height: 65vh;
}
</style>
